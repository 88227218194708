class BaseCache {
  constructor(options = {}) {
    this.options = {
      duration: 3600000,
      maxSize: 100,
      ...options,
    };
  }
}

export default BaseCache;
