// @ts-nocheck
import React, { useState, useEffect } from "react";
import PhotoFlowComponent from "./PhotoFlowComponent";
import LoadPhotoProofContent from "./PhotoProofContent";
import { useParams } from "react-router-dom";
import { useShipments } from "../../hooks/shipments";
import Select from "react-select";
import { useUploadPhoto } from "../../hooks/photos";

const LoadPhotoProof = () => {
  const { shipmentId: paramShipmentId } = useParams();
  const [shipmentId, setShipmentId] = useState(paramShipmentId || "");
  const [currentStep, setCurrentStep] = useState(1);
  const steps = ["Upload Photo", "Review Photo", "Submit"];

  // Lift state up to LoadPhotoProof
  const [frontPhoto, setFrontPhoto] = useState(null);
  const [frontPreviewUrl, setFrontPreviewUrl] = useState("");
  const [backPhoto, setBackPhoto] = useState(null);
  const [backPreviewUrl, setBackPreviewUrl] = useState("");
  const [leftPhoto, setLeftPhoto] = useState(null);
  const [leftPreviewUrl, setLeftPreviewUrl] = useState("");
  const [rightPhoto, setRightPhoto] = useState(null);
  const [rightPreviewUrl, setRightPreviewUrl] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Fetch shipments using useShipments hook
  const {
    data: shipments,
    isLoading: isLoadingShipments,
    error: shipmentsError,
  } = useShipments();

  const uploadPhotoMutation = useUploadPhoto();

  useEffect(() => {
    console.log("paramShipmentId:", paramShipmentId); // Log paramShipmentId
    if (paramShipmentId) {
      setShipmentId(paramShipmentId);
    }
  }, [paramShipmentId]);

  const handleSubmit = async () => {
    console.log("Clicked submit");
    if (!shipmentId || !frontPhoto || !backPhoto || !leftPhoto || !rightPhoto) {
      let missingItems = [];
      if (!shipmentId) missingItems.push("shipment ID");
      if (!frontPhoto) missingItems.push("front photo");
      if (!backPhoto) missingItems.push("back photo");
      if (!leftPhoto) missingItems.push("left photo");
      if (!rightPhoto) missingItems.push("right photo");

      alert(`Please provide the following: ${missingItems.join(", ")}.`);
      return;
    }

    const photos = [
      { side: "front", photo: frontPhoto },
      { side: "back", photo: backPhoto },
      { side: "left", photo: leftPhoto },
      { side: "right", photo: rightPhoto },
    ];

    try {
      console.log("Uploading photos");
      for (const { side, photo } of photos) {
        await uploadPhotoMutation.mutateAsync({
          imageFile: photo,
          latitude: 0, // Replace with actual latitude if available
          longitude: 0, // Replace with actual longitude if available
          side: side,
          takenBy: "user", // Replace with actual user info if available
          loadId: shipmentId,
        });
      }

      alert("Photos saved successfully");
    } catch (error) {
      console.error("Error saving photos:", error);
      alert("An error occurred while saving the photos.");
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      handleSubmit();
    }
  };

  // Prepare shipment options for react-select
  const shipmentOptions =
    shipments?.shipments.map((shipment) => ({
      value: shipment.load_id,
      label: `${shipment.load_id} - ${shipment.description}`,
    })) || [];

  return (
    <PhotoFlowComponent
      title="Load Photo Proof"
      subtitle="Please complete the following steps"
      steps={steps}
      currentStep={currentStep}
      onSubmit={handleNext}
      buttonText={currentStep === steps.length ? "Submit" : "Next"}
      isButtonDisabled={isButtonDisabled}
    >
      {isLoadingShipments && <div>Loading shipments...</div>}
      {shipmentsError && (
        <div>Error loading shipments. Please try again later.</div>
      )}

      {!shipmentId && !isLoadingShipments && !shipmentsError && (
        <div className="flex flex-col items-center justify-center h-full w-full mb-12">
          <label
            htmlFor="shipmentId"
            className="text-sm font-medium text-gray-700 mb-2"
          >
            Select Shipment ID:
          </label>
          <Select
            options={shipmentOptions}
            value={shipmentOptions.find(
              (option) => option.value === shipmentId
            )}
            onChange={(option) => setShipmentId(option ? option.value : "")}
            placeholder="Select Shipment"
            isSearchable
            className="w-full text-slate-600"
          />
        </div>
      )}

      {shipmentId && (
        <LoadPhotoProofContent
          shipmentId={shipmentId}
          frontPhoto={frontPhoto}
          setFrontPhoto={setFrontPhoto}
          frontPreviewUrl={frontPreviewUrl}
          setFrontPreviewUrl={setFrontPreviewUrl}
          backPhoto={backPhoto}
          setBackPhoto={setBackPhoto}
          backPreviewUrl={backPreviewUrl}
          setBackPreviewUrl={setBackPreviewUrl}
          leftPhoto={leftPhoto}
          setLeftPhoto={setLeftPhoto}
          leftPreviewUrl={leftPreviewUrl}
          setLeftPreviewUrl={setLeftPreviewUrl}
          rightPhoto={rightPhoto}
          setRightPhoto={setRightPhoto}
          rightPreviewUrl={rightPreviewUrl}
          setRightPreviewUrl={setRightPreviewUrl}
          handleSubmit={handleSubmit} // Pass handleSubmit as a prop
        />
      )}
    </PhotoFlowComponent>
  );
};

export default LoadPhotoProof;
