import React from "react";
import {
  Package,
  Clock,
  Truck,
  CheckCircle,
  AlertTriangle,
  XCircle,
} from "lucide-react";

const StatCard = ({ title, value, color, icon: Icon }) => (
  <div className="flex-1 min-w-[150px] p-4 bg-slate-50 rounded-lg border border-slate-200 flex flex-col justify-between">
    <div className="flex justify-between items-start">
      <div>
        <p className="text-slate-800 text-xs sm:text-sm font-semibold opacity-70">
          {title}
        </p>
        <p className="text-slate-800 text-base sm:text-lg font-semibold mt-2">
          {value}
        </p>
      </div>
      <div
        className={`w-10 h-10 ml-2 bg-opacity-20 rounded-full flex items-center justify-center ${color}`}
      >
        <Icon className={`w-6 h-6 ${color.replace("bg-", "text-")}`} />
      </div>
    </div>
  </div>
);

const SummaryStats = ({ data }) => {
  const initialCounts = {
    shipmentsPending: 0,
    shipmentsInTransit: 0,
    shipmentsDelivered: 0,
    shipmentsDelayed: 0,
    shipmentsLost: 0,
    shipmentsSubmitted: 0,
  };

  const counts = data.reduce((acc, shipment) => {
    acc.shipmentsSubmitted += 1;
    switch (shipment.condition) {
      case "Good":
        acc.shipmentsPending += 1;
        break;
      case "Damaged":
        acc.shipmentsInTransit += 1;
        break;
      case "Slightly Damaged":
        acc.shipmentsDelivered += 1;
        break;
      default:
        break;
    }
    return acc;
  }, initialCounts);

  const {
    shipmentsPending,
    shipmentsInTransit,
    shipmentsDelivered,
    shipmentsDelayed,
    shipmentsLost,
    shipmentsSubmitted,
  } = counts;

  return (
    <div className="rounded-xl space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
        <StatCard
          title="Good"
          value={shipmentsSubmitted}
          color="bg-blue-400"
          icon={Package}
        />
        <StatCard
          title="Damaged"
          value={shipmentsPending}
          color="bg-yellow-400"
          icon={Clock}
        />
        <StatCard
          title="Slightly Damaged"
          value={shipmentsInTransit}
          color="bg-green-400"
          icon={Truck}
        />
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div className="text-center lg:text-left lg:flex lg:flex-col lg:items-center lg:justify-center lg:mr-12 mb-8 lg:mb-0"></div>
        <div className="lg:w-1/2 flex justify-center lg:justify-end"></div>
      </div>
    </div>
  );
};

export default SummaryStats;
