// @ts-nocheck
import { supabase } from "../lib/supabase";
import { Buffer } from "buffer";
import CacheService from "./CacheService";

const cache = CacheService.getInstance();

/**
 * Service for handling photo-related operations.
 */
export const PhotoService = {
  /**
   * Gets the current authenticated user
   * @returns {Promise<Object>} The user data
   */
  getCurrentUser: async () => {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    if (error) throw error;
    if (!session?.user) throw new Error("No user logged in");
    return session.user;
  },

  /**
   * Uploads a photo to the server.
   * @param {Object} params - The parameters for uploading a photo.
   * @param {File} params.imageFile - The image file to upload.
   * @param {number} params.latitude - The latitude where the photo was taken.
   * @param {number} params.longitude - The longitude where the photo was taken.
   * @param {string} params.side - The side of the load the photo represents.
   * @param {string} params.takenBy - The identifier of who took the photo.
   * @param {string} params.loadId - The ID of the load associated with the photo.
   * @returns {Promise<Object>} The uploaded photo data.
   * @throws {Error} If there's an error during the upload process.
   */
  uploadPhoto: async ({
    imageFile,
    latitude,
    longitude,
    side,
    takenBy,
    loadId,
  }) => {
    const logContext = {
      loadId,
      side,
      timestamp: new Date().toISOString(),
    };

    try {
      // Input validation with more specific error messages
      if (!imageFile) {
        throw new Error("Image file is required");
      }

      if (!loadId || typeof loadId !== "string") {
        throw new Error(`Invalid load ID: ${loadId}`);
      }

      if (!side || typeof side !== "string") {
        throw new Error(`Invalid side parameter: ${side}`);
      }

      // Get current user
      let user;
      try {
        user = await PhotoService.getCurrentUser();
      } catch (error) {
        console.error("Failed to get current user:", { ...logContext, error });
        throw new Error(`Authentication error: ${error.message}`);
      }

      // Convert image to base64
      let base64;
      try {
        base64 = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result.split(",")[1]);
          };
          reader.onerror = (error) => {
            reject(new Error(`File reading failed: ${error}`));
          };
          reader.readAsDataURL(imageFile);
        });
      } catch (error) {
        throw new Error(`Image processing error: ${error.message}`);
      }

      const fileData = Buffer.from(base64, "base64");

      // Get user data
      let userData;
      try {
        const { data, error: userError } = await supabase
          .from("customers")
          .select("company_id")
          .eq("user_id", user.id)
          .single();

        if (userError) throw userError;
        userData = data;
      } catch (error) {
        throw new Error(`Failed to fetch user data: ${error.message}`);
      }

      if (!userData?.company_id) {
        throw new Error("Company ID not found for the user");
      }

      // Generate filepath with sanitized values
      const timestamp = new Date().getTime();
      const sanitizedLoadId = loadId.replace(/[^a-zA-Z0-9-]/g, "");
      const sanitizedSide = side.replace(/[^a-zA-Z0-9-]/g, "");
      const filePath = `${user.id}/${sanitizedLoadId}/${sanitizedSide}-${timestamp}.png`;

      // Upload file
      try {
        const { error: uploadError } = await supabase.storage
          .from("loadPhotos")
          .upload(filePath, fileData, {
            contentType: "image/png",
            upsert: true,
            metadata: {
              user_id: user.id,
              company_id: userData.company_id,
              original_name: imageFile.name,
              upload_timestamp: timestamp,
            },
          });

        if (uploadError) throw uploadError;
      } catch (error) {
        throw new Error(`Failed to upload file: ${error.message}`);
      }

      // Insert photo record
      let photoRecord;
      try {
        const { data, error: insertError } = await supabase
          .from("photos")
          .insert({
            photo_url: filePath,
            origin_latitude: latitude,
            origin_longitude: longitude,
            side: side,
            taken_by: user.id,
            load_id: loadId,
          })
          .select()
          .single();

        if (insertError) throw insertError;
        photoRecord = data;
        console.log("Successfully inserted photo record:", {
          ...logContext,
          photoId: photoRecord.id,
        });
      } catch (error) {
        throw new Error(`Failed to insert photo record: ${error.message}`);
      }

      // Update shipment photo
      try {
        // First get current photo
        console.log("Fetching current shipment data:", { ...logContext });

        // Get all photos for this load
        const { data: existingPhotoRecords, error: photoError } = await supabase
          .from("photos")
          .select("photo_url, side")
          .eq("load_id", loadId);

        if (photoError) {
          console.error("Error fetching existing photos:", {
            ...logContext,
            error: photoError,
          });
          throw new Error(
            `Failed to fetch existing photos: ${photoError.message}`
          );
        }

        // Create a complete photo mapping from existing records
        const allPhotos = {};
        existingPhotoRecords?.forEach((record) => {
          allPhotos[record.side] = record.photo_url;
        });

        // Add the new photo
        allPhotos[side] = filePath;

        console.log("Compiled complete photo mapping:", {
          ...logContext,
          allPhotos,
        });

        // Now get the shipment
        const {
          data: shipments,
          count,
          error: countError,
        } = await supabase
          .from("shipments")
          .select("photo, id", { count: "exact" })
          .eq("load_id", loadId);

        if (countError) {
          console.error("Error counting shipments:", {
            ...logContext,
            error: countError,
          });
          throw new Error(`Failed to check shipments: ${countError.message}`);
        }

        console.log("Found shipments:", {
          ...logContext,
          count,
          shipmentIds: shipments?.map((s) => s.id),
        });

        // Handle no shipments found
        if (!shipments || shipments.length === 0) {
          console.error("No shipment found with this load_id:", loadId);
          throw new Error(`No shipment found with load_id: ${loadId}`);
        }

        // Handle multiple shipments found
        if (shipments.length > 1) {
          console.warn("Multiple shipments found with this load_id:", {
            loadId,
            count: shipments.length,
            shipmentIds: shipments.map((s) => s.id),
          });
        }

        // Use the first shipment (or the only one)
        const currentShipment = shipments[0];
        console.log("Using shipment:", {
          ...logContext,
          shipmentId: currentShipment.id,
        });

        // Create the full photo JSON structure
        const photoJson = {
          photos: allPhotos,
          metadata: {
            lastUpdated: new Date().toISOString(),
            totalPhotos: Object.keys(allPhotos).length,
            sides: Object.keys(allPhotos),
          },
        };

        console.log("Updating shipment with complete photo data:", {
          ...logContext,
          photoJson,
          shipmentId: currentShipment.id,
        });

        // Update shipment
        const { error: updateError } = await supabase
          .from("shipments")
          .update({
            photo: photoJson,
            updated_at: new Date().toISOString(),
          })
          .eq("id", currentShipment.id)
          .eq("load_id", loadId)
          .single();

        if (updateError) {
          console.error("Error updating shipment:", {
            ...logContext,
            shipmentId: currentShipment.id,
            error: updateError,
          });
          throw new Error(`Failed to update shipment: ${updateError.message}`);
        }

        console.log("Successfully updated shipment with complete photo data:", {
          ...logContext,
          shipmentId: currentShipment.id,
          photoJson,
        });
      } catch (error) {
        // Log the full error details
        console.error("Shipment update error:", {
          error,
          context: logContext,
          errorDetails: {
            message: error.message,
            code: error.code,
            details: error.details,
            hint: error.hint,
          },
        });
        throw error;
      }

      return photoRecord;
    } catch (error) {
      // Enhance error logging
      console.error("Photo upload failed:", {
        originalError: error,
        context: logContext,
        errorDetails: {
          message: error.message,
          code: error.code,
          details: error.details,
          hint: error.hint,
          stack: error.stack,
        },
      });

      // Rethrow with more context
      throw new Error(`Photo upload failed: ${error.message}`);
    }
  },

  /**
   * Retrieves photos with optional filtering
   * @param {Object} params
   * @param {string} [params.loadId] - Optional load ID to filter photos
   * @returns {Promise<Array>} Array of photo objects
   */
  getPhotos: async ({ loadId } = {}) => {
    let query = supabase
      .from("photos")
      .select(
        `
        id,
        photo_url,
        stage_name,
        origin_latitude,
        origin_longitude,
        metadata,
        tags,
        created_at
      `
      )
      .order("created_at", { ascending: false });

    if (loadId) {
      query = query.eq("load_id", loadId);
    }

    const { data, error } = await query;

    if (error) {
      console.error("Error fetching photos:", error);
      throw error;
    }

    return data;
  },

  /**
   * Retrieves a signed URL for a photo with caching
   * @param {string} path - The path of the photo
   * @returns {Promise<string>} The signed URL
   */
  getPhotoUrl: async (path) => {
    const cacheKey = `photo_url_${path}`;

    // Check cache first
    const cachedUrl = await cache.get(cacheKey);
    if (cachedUrl) {
      console.debug("Cache hit:", cacheKey);
      return cachedUrl;
    }

    console.debug("Cache miss:", cacheKey);

    // Fetch signed URL
    const { data, error } = await supabase.storage
      .from("loadPhotos")
      .createSignedUrl(path, 3600);

    if (error) {
      console.error("Error fetching signed URL:", error);
      throw error;
    }

    // Cache the URL
    await cache.set(cacheKey, data.signedUrl, 3300); // Cache for 55 minutes
    return data.signedUrl;
  },

  /**
   * Clears the cache for a specific photo or all photos
   * @param {string} [path] - Optional path to clear specific photo cache
   */
  clearPhotoCache: (path) => {
    if (path) {
      const cacheKey = `photo_url_${path}`;
      cache.memoryCache.delete(cacheKey);
      cache.storageCache.delete(cacheKey);
    } else {
      cache.memoryCache.clear();
      cache.storageCache.clear();
    }
  },

  /**
   * Retrieves details for a specific photo.
   * @param {string} loadId - The ID of the load.
   * @param {string} side - The side of the load.
   * @returns {Promise<Object|null>} The photo details or null if not found.
   * @throws {Error} If there's an error retrieving the photo details.
   */
  getPhotoDetails: async (loadId, side) => {
    // Check cache first
    const cacheKey = `photo_details_${loadId}_${side}`;
    const cachedData = sessionStorage.getItem(cacheKey);
    if (cachedData) {
      return JSON.parse(cachedData);
    }

    const { data: photoRecord, error: photoError } = await supabase
      .from("photos")
      .select("*")
      .eq("load_id", loadId)
      .eq("side", side)
      .single();

    if (photoError) {
      if (photoError.code === "PGRST116") {
        return null;
      }
      throw photoError;
    }

    if (!photoRecord) {
      return null;
    }

    const { data: urlData, error: signedUrlError } = await supabase.storage
      .from("loadPhotos")
      .createSignedUrl(photoRecord.photo_url, 60 * 60);

    if (signedUrlError) {
      throw signedUrlError;
    }

    const result = {
      ...photoRecord,
      signedUrl: urlData.signedUrl,
    };

    // Cache the result for 30 minutes
    sessionStorage.setItem(cacheKey, JSON.stringify(result));
    setTimeout(() => sessionStorage.removeItem(cacheKey), 30 * 60 * 1000);

    return result;
  },

  /**
   * Downloads a photo.
   * @param {string} path - The path of the photo to download.
   * @returns {Promise<string>} The public URL of the photo.
   */
  downloadPhoto: async (path) => {
    console.log(`Generating public URL for photo: ${path}`);
    const { data } = await supabase.storage
      .from("loadPhotos")
      .getPublicUrl(path);

    console.log(`Public URL generated for photo: ${path}`);
    return data.publicUrl;
  },
};
