import { z } from "zod";

// Validation schema
export const driverSchema = z.object({
  user_id: z.string().uuid().nullable(),
  first_name: z.string().min(1, "First name is required").max(255),
  last_name: z.string().min(1, "Last name is required").max(255),
  date_of_birth: z.string().regex(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format"),
  phone_number: z
    .string()
    .max(15)
    .regex(/^\+?[1-9]\d{1,14}$/, "Invalid phone number"),
  email: z.string().email("Invalid email address").max(255),
  driver_license_number: z.string().max(255).nullable(),
  driver_license_state: z.string().max(255).nullable(),
  driver_license_expiration_date: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format")
    .nullable(),
  driver_license_class: z.string().max(50).nullable(),
  company_id: z.number().int().positive().nullable(),
  employment_start_date: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format")
    .nullable(),
  employment_status: z
    .enum(["Active", "Inactive", "Suspended"])
    .default("Active")
    .nullable(),
  is_verified: z.boolean().default(false).nullable(),
  verification_date: z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, "Invalid date format")
    .nullable(),
  verified_by: z.string().uuid().nullable(),
  safety_violations: z.string().nullable(),
  safety_score: z.number().int().min(0).max(100).default(100).nullable(),
});
