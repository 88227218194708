import React from "react";

export default function Example() {
  return (
    <>
      <div className="relative isolate min-h-screen">
        <img
          alt=""
          src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
          className="absolute inset-0 -z-10 w-full h-full object-cover"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
          <h1
            className="mt-4 text-balance text-5xl font-bold tracking-tight text-white sm:text-7xl"
            style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}
          >
            Sorry!
          </h1>
          <p
            className="mt-6 text-pretty text-lg font-bold text-white sm:text-xl/8"
            style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)" }}
          >
            Sorry, we couldn’t find the page you’re looking for or you are not
            authorized to see this page.
          </p>
          <div className="mt-10 flex justify-center">
            <a href="/" className="text-sm/7 font-semibold text-white">
              <span aria-hidden="true">&larr;</span> Back to home
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
