import React from "react";
import AddCargoOwner from "./AddCargoOwner";

const AddCargoOwnerForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row gap-10 p-10">
      <AddCargoOwner />
    </div>
  );
};

export default AddCargoOwnerForm;
