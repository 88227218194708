import React from "react";

const InputField = ({
  label,
  placeholder,
  type = "text",
  icon,
  htmlFor,
  value,
  onChange,
  name,
}) => (
  <div className="flex flex-col space-y-1.5 mb-4">
    <label htmlFor={htmlFor} className="text-slate-900 text-sm font-medium">
      {label}
    </label>
    <div className="relative">
      <input
        id={htmlFor}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        className="w-full px-3 py-2 bg-white rounded-md border border-slate-300 text-slate-700 text-base"
      />
      {icon && (
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#7b61ff]">
          {icon}
        </div>
      )}
    </div>
  </div>
);

export default InputField;
