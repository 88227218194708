// @ts-nocheck
import React, { useState } from "react";
import Header from "../Portal/Header";
import Filter from "./DriverFilter";
import SummaryStats from "./DriverStats";
import DataTableDriver from "./DriverDataTable";
import { useDrivers } from "../../hooks/drivers";

// FleetDashboard.js
const DashboardContent = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: driversData, isLoading, error } = useDrivers(page, limit);
  console.log("Drivers data", driversData);

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (!driversData || driversData.drivers.length === 0) {
    return (
      <div className="rounded-lg shadow-md p-8 text-center">
        <p className="text-xl text-gray-400">No data available</p>
      </div>
    );
  }

  return (
    <div className="bg-white text-gray-300">
      <Header companyName="Fleet Management" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <SummaryStats data={driversData.drivers} />
        <div className="mt-8">
          <DataTableDriver data={driversData.drivers} />
        </div>
      </div>
    </div>
  );
};

const FleetDashboard = () => <DashboardContent />;

export default FleetDashboard;
