import BaseCache from "./BaseCache";

class StorageCache extends BaseCache {
  constructor(prefix, options) {
    super(options);
    this.prefix = prefix;
  }

  getKey(key) {
    return `${this.prefix}_${key}`;
  }

  get(key) {
    try {
      const stored = localStorage.getItem(this.getKey(key));
      if (!stored) return null;

      const entry = JSON.parse(stored);

      if (Date.now() - entry.timestamp > this.options.duration) {
        this.delete(key);
        return null;
      }

      return entry.value;
    } catch {
      return null;
    }
  }

  set(key, value) {
    try {
      localStorage.setItem(
        this.getKey(key),
        JSON.stringify({
          value,
          timestamp: Date.now(),
        })
      );
    } catch (e) {
      console.warn("StorageCache set failed:", e);
    }
  }

  delete(key) {
    localStorage.removeItem(this.getKey(key));
  }

  clear() {
    Object.keys(localStorage)
      .filter((key) => key.startsWith(this.prefix))
      .forEach((key) => localStorage.removeItem(key));
  }
}

export default StorageCache;
