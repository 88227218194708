// @ts-nocheck
import React, { useState, useEffect } from "react";
import CustomDatePicker from "../../Helper/DatePicker";
import CustomTimePicker from "../../Helper/TimePicker";
import InputField from "../../Components/InputField";
import { useCustomerCompanyId } from "../../../hooks/customers";
import { useGetUserSession } from "../../../hooks/users";
import { useShipmentMutations } from "../../../hooks/shipments";
import { useCargoOwnerList } from "../../../hooks/cargoOwner";
import { Link } from "react-router-dom";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

const AddShipment = () => {
  const { session, loading } = useGetUserSession();
  const { data: companyId } = useCustomerCompanyId(session?.user?.id);
  const { createShipment } = useShipmentMutations();
  const {
    data: cargoOwners,
    isLoading: isLoadingCargoOwners,
    error: cargoOwnersError,
    isError,
  } = useCargoOwnerList();

  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    load_id: "",
    origin_address: "",
    destination_address: "",
    cargo_owner_id: "",
    carrier_number: "",
    company_id: companyId || "",
  });

  useEffect(() => {
    console.log("Loading:", isLoadingCargoOwners);
    console.log("Error:", cargoOwnersError);
    console.log("Data:", cargoOwners);
  }, [isLoadingCargoOwners, cargoOwnersError, cargoOwners]);

  useEffect(() => {
    if (companyId) {
      setFormData((prevData) => ({ ...prevData, company_id: companyId }));
    }
  }, [companyId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    console.error("Error loading cargo owners:", cargoOwnersError);
    return <div>Error loading cargo owners. Please try again.</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form", formData);

    try {
      // Generate UUID if load_id is empty
      const submissionData = {
        ...formData,
        load_id: formData.load_id.trim() || uuidv4(),
      };

      // Use the createShipment mutation with the potentially modified load_id
      const result = await createShipment.mutateAsync({
        company_id: submissionData.company_id,
        load_id: submissionData.load_id,
        origin_address: submissionData.origin_address,
        destination_address: submissionData.destination_address,
        cargo_owner_id: submissionData.cargo_owner_id,
        carrier_number: submissionData.carrier_number,
      });
      console.log("Result", result);

      setMessage(`Shipment created successfully.`);
      setError("");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  const cargoOwnerOptions =
    cargoOwners?.map((owner) => ({
      value: owner.id,
      label: owner.company_name,
    })) || [];

  const handleSelectChange = (name, option) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: option ? option.value : "",
    }));
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-1/6 flex justify-center items-start">
          <img
            src="/static/warranty.png"
            alt="Warranty"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              Add Shipment
            </h2>
            <p className="text-slate-500 text-sm">
              Enter shipment information.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              <InputField
                label="Load ID"
                placeholder="1234567890"
                name="load_id"
                value={formData.load_id}
                onChange={handleInputChange}
              />
              <InputField
                label="Origin Address"
                placeholder="1 Apple Way, Cupertino, CA 95014"
                name="origin_address"
                value={formData.origin_address}
                onChange={handleInputChange}
              />
              <InputField
                label="Destination Address"
                placeholder="1 Google Way, Mountain View, CA 94043"
                name="destination_address"
                value={formData.destination_address}
                onChange={handleInputChange}
              />
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="cargo_owner_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cargo Owner
                </label>
                <div>
                  <Select
                    options={cargoOwnerOptions}
                    value={cargoOwnerOptions.find(
                      (option) => option.value === formData.cargo_owner_id
                    )}
                    onChange={(option) =>
                      handleSelectChange("cargo_owner_id", option)
                    }
                    placeholder="Select Cargo Owner"
                    isSearchable
                    className="w-full text-slate-600"
                    isDisabled={isLoadingCargoOwners}
                  />
                  <Link
                    to="/dashboard/add-cargo-owner"
                    className="text-blue-500 text-sm mt-1 block"
                  >
                    Cargo owner not listed? Add a new cargo owner
                  </Link>
                </div>
              </div>
            </div>

            {error && <p className="text-red-500 mt-6">{error}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}

            <div className="space-y-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddShipment;
