import React from "react";
import AddDriver from "./AddDriver";

const AddDriverForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row gap-10 p-10">
      <AddDriver />
    </div>
  );
};

export default AddDriverForm;
