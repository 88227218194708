// @ts-nocheck
import React, { useState } from "react";
import { motion } from "framer-motion";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const TableHeader = () => (
  <thead className="bg-zinc-100 text-xs uppercase text-gray-700">
    <tr>
      {[
        "ID",
        "Name",
        "Email",
        "License Number",
        "License State",
        "Employment Status",
        "Safety Score",
        "Actions",
      ].map((header) => (
        <th
          key={header}
          className="px-4 py-2 text-left font-normal text-zinc-600"
        >
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const TableCell = ({ children, icon: Icon }) => (
  <td className="px-4 py-2 text-sm">
    <div className="flex items-center space-x-2">
      {Icon && <Icon size={16} className="text-gray-700" />}
      <span className="text-gray-600">{children}</span>
    </div>
  </td>
);

const ExpandButton = ({ isExpanded, onClick }) => (
  <button
    onClick={onClick}
    className="flex items-center space-x-1 text-blue-600 hover:text-blue-800"
  >
    {isExpanded ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
    <span className="sr-only">{isExpanded ? "Hide" : "View"} Details</span>
  </button>
);

const RowDetails = ({ row }) => {
  const navigate = useNavigate();

  const handleVerification = () => {
    navigate(`/driver-verification/${row.id}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-gray-100"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-50 p-4 text-sm">
        <div>
          <h4 className="font-semibold text-slate-600">Personal Details</h4>
          <p className="text-slate-500">Date of Birth: {row.date_of_birth}</p>
          <p className="text-slate-500">Phone Number: {row.phone_number}</p>
          <p className="text-slate-500">User ID: {row.user_id}</p>
        </div>
        <div>
          <h4 className="font-semibold text-slate-600">License Information</h4>
          <p className="text-slate-500">
            License Class: {row.driver_license_class}
          </p>
          <p className="text-slate-500">
            Expiration Date: {row.driver_license_expiration_date}
          </p>
        </div>
        <div>
          <h4 className="font-semibold text-slate-600">
            Employment Information
          </h4>
          <p className="text-slate-500">Company ID: {row.company_id}</p>
          <p className="text-slate-500">
            Start Date: {row.employment_start_date}
          </p>
          <p className="text-slate-500">
            Verification Date: {row.verification_date || "Not verified"}
          </p>
        </div>
        <div>
          <h4 className="font-semibold text-slate-600">Safety Information</h4>
          <p className="text-slate-500">
            Violations: {row.safety_violations || "None"}
          </p>
          <p className="text-slate-500">Safety Score: {row.safety_score}</p>
        </div>
        <div className="col-span-2">
          <button
            onClick={handleVerification}
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors"
          >
            {row.is_verified ? "Update Verification" : "Verify Driver"}
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const TableRow = ({ row, index, expandedRow, setExpandedRow }) => {
  const isExpanded = expandedRow === index;

  return (
    <>
      <tr className="border-b border-gray-200 hover:bg-gray-50 group">
        <TableCell>{row.id}</TableCell>
        <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.driver_license_number}</TableCell>
        <TableCell>{row.driver_license_state}</TableCell>
        <TableCell>{row.employment_status}</TableCell>
        <TableCell>{row.safety_score}</TableCell>
        <td className="px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <ExpandButton
            isExpanded={isExpanded}
            onClick={() => setExpandedRow(isExpanded ? null : index)}
          />
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan="8">
            <RowDetails row={row} />
          </td>
        </tr>
      )}
    </>
  );
};

const DataTableDriver = ({ data }) => {
  const [expandedRow, setExpandedRow] = useState(null);

  return (
    <div className="overflow-x-auto bg-white rounded-lg shadow">
      <table className="min-w-full table-auto">
        <TableHeader />
        <tbody>
          {data.map((row, index) => (
            <TableRow
              key={row._id}
              row={row}
              index={index}
              expandedRow={expandedRow}
              setExpandedRow={setExpandedRow}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTableDriver;
