import React from "react";

const PhotoCapture = ({
  label,
  photo,
  setPhoto,
  previewUrl,
  setPreviewUrl,
}) => {
  const handleCapture = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPhoto(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof setPreviewUrl === "function") {
          setPreviewUrl(reader.result);
        } else {
          console.error("setPreviewUrl is not a function");
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClearPhoto = () => {
    setPhoto(null);
    setPreviewUrl(null);
  };

  return (
    <div className="w-full px-4 py-2 bg-slate-200 rounded-md flex flex-col justify-center items-center gap-2 relative">
      <label
        htmlFor={`${label}-input`}
        className="text-slate-800 text-sm font-medium cursor-pointer"
      >
        {label}
      </label>

      <input
        id={`${label}-input`}
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleCapture}
        className="hidden"
      />

      {previewUrl && (
        <div className="relative">
          <button
            onClick={handleClearPhoto}
            className="w-full px-4 py-2 bg-slate-300 rounded-md text-slate-900 text-sm font-medium font-['Sharp Sans Display No1'] leading-normal"
            aria-label="Clear photo"
          >
            X
          </button>
          <img
            src={previewUrl}
            alt={`${label} Preview`}
            className="w-32 h-32 object-cover rounded-md mt-2"
          />
        </div>
      )}
    </div>
  );
};

export default PhotoCapture;
