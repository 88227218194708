import React from 'react';

const FailurePage = ({ personaFailed, ocrFailed }) => (
  <div className="text-center py-4">
    <h3 className="text-2xl font-semibold text-red-600">Verification Failed</h3>
    <p className="mt-2">The following verifications failed:</p>
    <ul className="list-disc list-inside mt-2">
      {personaFailed && <li>Identity Verification</li>}
      {ocrFailed && <li>Document Verification</li>}
    </ul>
    <p className="mt-4">Please try again or contact support for assistance.</p>
  </div>
);

export default FailurePage;