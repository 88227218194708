// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../Portal/Header";
import Filter from "./DriversFilter";
import SummaryStats from "./DriverStats";
import DataTable from "./DriverTable";
import { useDrivers } from "../../hooks/drivers";

// FleetDashboard.js
const DriverDashboardContent = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: driversData, isLoading, error } = useDrivers();

  useEffect(() => {
    if (driversData) {
      const totalDrivers = driversData.drivers.length;
      setTotalPages(Math.ceil(totalDrivers / limit));
    }
  }, [driversData, limit]); // Recalculate totalPages when shipmentsData or limit changes

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (!driversData || driversData.drivers.length === 0) {
    return (
      <div className="rounded-lg shadow-md p-8 text-center">
        <p className="text-xl text-gray-400">No drivers available</p>
      </div>
    );
  }

  const handlePageChange = (newPage) => {
    console.log("Triggered");
    setPage(newPage);
  };

  return (
    <div className="bg-white text-gray-300">
      <Header companyName="Drivers" url="/dashboard/add-driver" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <SummaryStats data={driversData?.drivers || []} />
        <div className="mt-8">
          <DataTable
            data={driversData?.drivers || []}
            currentPage={page}
            totalPages={totalPages} // Use the locally calculated totalPages
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const DriverDashboard = () => <DriverDashboardContent />;

export default DriverDashboard;
