export const cacheConfig = {
  memory: {
    duration: 3600000, // 1 hour
    maxSize: 100,
  },
  storage: {
    duration: 86400000, // 24 hours
    maxSize: 1000,
  },
};
