// @ts-nocheck

// Create a new file called Toast.js
const Toast = {
  init() {
    // Create toast container if it doesn't exist
    if (!document.getElementById("toast-container")) {
      const container = document.createElement("div");
      container.id = "toast-container";
      container.className = "fixed top-4 right-4 z-50 flex flex-col gap-2";
      document.body.appendChild(container);
    }
  },

  show({ message, type = "success", duration = 3000 }) {
    this.init();
    const container = document.getElementById("toast-container");

    // Create toast element
    const toast = document.createElement("div");
    toast.className = `
        transform translate-x-full opacity-0 transition-all duration-300 ease-out
        flex items-center p-4 rounded-lg shadow-lg text-white
        ${type === "success" ? "bg-green-500" : ""}
        ${type === "error" ? "bg-red-500" : ""}
        ${type === "info" ? "bg-blue-500" : ""}
        ${type === "warning" ? "bg-yellow-500" : ""}
      `;

    // Add message
    toast.textContent = message;

    // Add to container
    container.appendChild(toast);

    // Trigger animation
    setTimeout(() => {
      toast.classList.remove("translate-x-full", "opacity-0");
    }, 50);

    // Remove after duration
    setTimeout(() => {
      toast.classList.add("translate-x-full", "opacity-0");
      setTimeout(() => {
        container.removeChild(toast);
      }, 300);
    }, duration);
  },

  success(message, duration) {
    this.show({ message, type: "success", duration });
  },

  error(message, duration) {
    this.show({ message, type: "error", duration });
  },

  info(message, duration) {
    this.show({ message, type: "info", duration });
  },

  warning(message, duration) {
    this.show({ message, type: "warning", duration });
  },
};

export default Toast;
