import React from "react";
import { BaseNode } from "./BaseNode";
import {
  EnvelopeIcon,
  ChatBubbleLeftIcon,
  BellIcon,
} from "@heroicons/react/20/solid";

export const NotificationNode = ({ data, isConnectable }) => {
  const getNotificationIcon = () => {
    switch (data.notificationType) {
      case "sms":
        return <ChatBubbleLeftIcon className="size-5 text-green-500" />;
      case "email":
        return <EnvelopeIcon className="size-5 text-blue-500" />;
      default:
        return <BellIcon className="size-5 text-yellow-500" />;
    }
  };

  return (
    <BaseNode data={data} isConnectable={isConnectable} type="notification">
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <select
                className="text-sm font-medium text-gray-900 bg-transparent border-0 border-b border-gray-300 focus:border-indigo-500 focus:ring-0 p-0"
                value={data.notificationType}
                onChange={(e) =>
                  data.onChange?.("notificationType", e.target.value)
                }
              >
                <option value="sms">SMS Notification</option>
                <option value="email">Email Notification</option>
                <option value="both">SMS & Email</option>
              </select>
              <span className="inline-flex shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Notification
              </span>
            </div>
            <div className="mt-3">
              <textarea
                placeholder="Notification message"
                className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.message || ""}
                onChange={(e) => data.onChange?.("message", e.target.value)}
                rows={3}
              />
            </div>
          </div>
          <div className="shrink-0 rounded-full bg-gray-100 p-2">
            {getNotificationIcon()}
          </div>
        </div>

        <div className="px-6 py-4">
          <div className="space-y-2">
            <label className="flex items-center space-x-2 text-sm text-gray-600">
              <input
                type="checkbox"
                checked={data.notifyDriver}
                onChange={(e) =>
                  data.onChange?.("notifyDriver", e.target.checked)
                }
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>Notify Driver</span>
            </label>
            <label className="flex items-center space-x-2 text-sm text-gray-600">
              <input
                type="checkbox"
                checked={data.notifyBroker}
                onChange={(e) =>
                  data.onChange?.("notifyBroker", e.target.checked)
                }
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>Notify Broker</span>
            </label>
            <label className="flex items-center space-x-2 text-sm text-gray-600">
              <input
                type="checkbox"
                checked={data.notifyCargoOwner}
                onChange={(e) =>
                  data.onChange?.("notifyCargoOwner", e.target.checked)
                }
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <span>Notify Cargo Owner</span>
            </label>
          </div>
        </div>
      </div>
    </BaseNode>
  );
};
