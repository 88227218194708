import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import DashboardLayout from "./pages/Portal/DashboardLayout";
import NavBar from "./pages/Components/NavBar";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import VerificationForm from "./pages/Verifications/AddVerifcation/VerificationPage";
import LoadPhotoProof from "./pages/PhotoProof/PhotoProof";
import DashboardContent from "./pages/Dashboard/DriverDashboard";
import ShipmentDashboardContent from "./pages/Shipments/ShipmentDashboard";
import ShipmentVerificationForm from "./pages/Verifications/AddVerifcation/ShipmentVerificationForm";
import AddCarrierForm from "./pages/Carriers/AddCarrier/AddCarrier";
import LandingPage from "./pages/LandingPage/LandingPage";
import AddShipmentForm from "./pages/Shipments/AddShipment/AddShipmentForm";
import DriversDashboard from "./pages/Drivers/DriversDashboard";
import AddDriverForm from "./pages/Drivers/AddDriver/AddDriverForm";
import CarriersDashboard from "./pages/Carriers/CarriersDashboard";
import CargoOwnerDashboard from "./pages/CargoOwners/CargoOwnerDashboard";
import AddCargoOwnerForm from "./pages/CargoOwners/AddCargoOwner/AddCargoOwnerForm";
import VerificationDashboard from "./pages/Verifications/VerificationsDashboard";
import ShipmentDetails from "./pages/Shipments/ViewShipment/ViewShipment";
import ReportDashboard from "./pages/Reports/ReportDashboard";
import AddReport from "./pages/Reports/AddReport/AddReport";
import ReportDetails from "./pages/Reports/ViewForm/ViewReport";
import PublicReport from "./pages/Reports/PublicReport/PublicReport";
import IdentityVerification from "./pages/VerificationForm/IdentityVerification";
import Workflow from "./pages/Workflow/AddWorkflow/Workflow";
import WorkflowDashboard from "./pages/Workflow/WorkflowDashboard";
import ExternalDashboard from "./pages/ExternalDashboard/ExternalDashboard";
import CustomerLogin from "./pages/ExternalDashboard/CustomerLogin";
import CargoOwnerUsers from "./pages/CargoOwners/CargoOwnerUsers";
import ProtectedRoute from "./hooks/protectedRoute";
import NotFound from "./pages/Unauthorized/404";

const App = () => {
  return (
    <Routes>
      {/* Public routes without NavBar */}
      <Route path="/public-report/:token" element={<PublicReport />} />
      <Route path="/verify/:shipmentId" element={<IdentityVerification />} />
      <Route path="/external/login" element={<CustomerLogin />} />
      <Route path="/external/:id" element={<ExternalDashboard />} />
      <Route path="/unauthorized" element={<NotFound />} />
      <Route
        path="/verification/:shipmentId"
        element={<ShipmentVerificationForm />}
      />

      {/* Routes with NavBar */}
      <Route
        element={
          <>
            <NavBar />
            <Outlet />
          </>
        }
      >
        <Route path="/" element={<LandingPage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/photo-proof/:shipmentId" element={<LoadPhotoProof />} />

        {/* Protected Dashboard Routes */}
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route index element={<DashboardContent />} />

            {/* Shipment Routes */}
            <Route path="shipments" element={<ShipmentDashboardContent />} />
            <Route path="add-shipment" element={<AddShipmentForm />} />
            <Route path="shipment/:id" element={<ShipmentDetails />} />

            {/* Driver Routes */}
            <Route path="drivers" element={<DriversDashboard />} />
            <Route path="add-driver" element={<AddDriverForm />} />
            {/* Carrier Routes */}
            <Route path="carriers" element={<CarriersDashboard />} />
            <Route path="add-carrier" element={<AddCarrierForm />} />
            {/* Cargo Owner Routes */}
            <Route path="cargo-owners" element={<CargoOwnerDashboard />} />
            <Route path="add-cargo-owner" element={<AddCargoOwnerForm />} />
            <Route
              path="cargo-owners/:id/users"
              element={<CargoOwnerUsers />}
            />

            {/* Verification Routes */}
            <Route path="verifications" element={<VerificationDashboard />} />
            <Route path="add-verification" element={<VerificationForm />} />
            {/* Report Routes */}
            <Route path="reports" element={<ReportDashboard />} />
            <Route path="add-report" element={<AddReport />} />
            <Route path="report/:id" element={<ReportDetails />} />
            {/* Workflow Routes */}
            <Route path="workflows" element={<WorkflowDashboard />} />
            <Route path="workflows/add-workflow" element={<Workflow />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default App;
