// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomDatePicker from "../../Helper/DatePicker";
import CustomTimePicker from "../../Helper/TimePicker";
import InputField from "../../Components/InputField";
import { createVerification } from "../../../api/VerificationService";
import { useCustomerCompanyId } from "../../../hooks/customers";
import { useGetUserSession } from "../../../hooks/users";

const VerificationForm = () => {
  const { shipmentId } = useParams();
  const { session, loading } = useGetUserSession();
  const { data: companyId } = useCustomerCompanyId(session?.user?.id);
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    shipmentID: shipmentId || "",
    phoneNumber: "",
    selectedDate: new Date(),
    selectedTime: new Date(),
    companyId: companyId || "",
  });

  useEffect(() => {
    if (companyId) {
      setFormData((prevData) => ({ ...prevData, companyId }));
    }
  }, [companyId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePhoneNumberChange = (e) => {
    const formatted = e.target.value.replace(/[^\d\s()-]/g, "");
    setFormData((prevData) => ({ ...prevData, phoneNumber: formatted }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreed) {
      setError("Please agree to the Terms of Use and Privacy Policy.");
      return;
    }

    try {
      const { data, error } = await createVerification({
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        shipmentID: formData.shipmentID,
        selectedDate: formData.selectedDate,
        selectedTime: formData.selectedTime,
        companyId: companyId,
      });

      if (error) {
        throw error;
      }

      setMessage(
        `Verification link sent via SMS to ${formData.phoneNumber}. Please check your phone.`
      );
      setError("");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-1/6 flex justify-center items-start">
          <img
            src="/static/warranty.png"
            alt="Warranty"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              24/7 Driver Verification
            </h2>
            <p className="text-slate-500 text-sm">
              Enter driver information. If you are not a driver, enter this
              information on their behalf.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              <InputField
                label="Driver First Name"
                placeholder="First name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <InputField
                label="Driver Last Name"
                placeholder="Last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <InputField
                label="Shipment ID to Verify"
                placeholder="1234567890"
                name="shipmentID"
                value={formData.shipmentID}
                onChange={handleInputChange}
                disabled
              />
              <InputField
                label="Phone Number"
                placeholder="(555) 555-5555"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <CustomDatePicker
                label="Date Delivery"
                selectedDate={formData.selectedDate}
                onChange={(date) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    selectedDate: date,
                  }))
                }
              />
              <CustomTimePicker
                label="Timezone"
                selectedTime={formData.selectedTime}
                onChange={(time) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    selectedTime: time,
                  }))
                }
              />
            </div>

            {error && <p className="text-red-500 mt-6">{error}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}

            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="terms"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  className="w-4 h-4 rounded border-gray-300"
                />
                <label htmlFor="terms" className="text-black text-sm">
                  I agree to our Terms of Service and Privacy Policy.
                </label>
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerificationForm;
