import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomTimePicker = ({ label, selectedTime, onChange }) => {
  return (
    <div className="space-y-1.5">
      <label className="text-slate-900 text-sm font-medium">
        {label}
      </label>
      <div className="relative">
        <DatePicker
          selected={selectedTime}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="h:mm aa"
          className="w-full px-3 py-2 bg-white rounded-md border border-slate-300 text-slate-400 text-base"
          placeholderText="Select a time"
        />
      </div>
    </div>
  );
};

export default CustomTimePicker;
