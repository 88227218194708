// @ts-nocheck
import React, { useState, useEffect, useCallback } from "react";
import Header from "../Portal/Header";
import Filter from "./CargoOwnerFilter";
import DataTable from "./CargoOwnerTable";
import { useCargoOwnerList } from "../../hooks/cargoOwner";

// FleetDashboard.js
const CargoOwnerDashboard = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const limit = 10;

  const { data: cargoOwnersData, isLoading, error } = useCargoOwnerList();
  console.log(cargoOwnersData);

  // Memoize the filter function
  const getFilteredData = useCallback(() => {
    if (!cargoOwnersData) return [];

    return cargoOwnersData.filter((cargoOwner) => {
      return Object.entries(filters).every(([key, value]) => {
        if (!value) return true; // Skip empty filters
        const ownerValue = cargoOwner[key]?.toString().toLowerCase();
        return ownerValue?.includes(value.toLowerCase());
      });
    });
  }, [cargoOwnersData, filters]); // Add dependencies for useCallback

  useEffect(() => {
    const filteredData = getFilteredData();
    setTotalPages(Math.ceil(filteredData.length / limit));
  }, [getFilteredData, limit]); // Update dependencies

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (!cargoOwnersData || cargoOwnersData.length === 0) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const handlePageChange = (newPage) => {
    console.log("Triggered");
    setPage(newPage);
  };

  return (
    <div className="bg-white text-gray-300">
      <Header companyName="Cargo Owners" url="/dashboard/add-cargo-owner" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <div className="mt-8">
          <DataTable
            data={getFilteredData()} // Use filtered data instead of raw data
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const CargoOwnerDashboardContent = () => <CargoOwnerDashboard />;

export default CargoOwnerDashboardContent;
