// @ts-nocheck
import { supabase } from "../lib/supabase";

export const ReportService = {
  async createReport(reportData) {
    const { data, error } = await supabase
      .from("shipment_reports")
      .insert(reportData)
      .select()
      .single();
    if (error) throw error;
    return data;
  },

  async getReports(page = 1, limit = 10) {
    const { data, error, count } = await supabase
      .from("shipment_reports")
      .select("*", { count: "exact" })
      .range((page - 1) * limit, page * limit - 1);
    if (error) throw error;
    return { reports: data, total: count };
  },

  async getAllReports() {
    const { data, error, count } = await supabase
      .from("shipment_reports")
      .select("*", { count: "exact" });
    if (error) throw error;
    return { reports: data, total: count };
  },

  async getReport(id) {
    const { data, error } = await supabase
      .from("shipment_reports")
      .select("*")
      .eq("id", id)
      .limit(1)
      .single();
    if (error) throw error;
    return data;
  },

  async updateReport(id, reportData) {
    const { data, error } = await supabase
      .from("shipment_reports")
      .update({ ...reportData, updated_at: new Date().toISOString() })
      .eq("id", id)
      .select()
      .single();
    if (error) throw error;
    return data;
  },

  async deleteReport(id) {
    const { error } = await supabase
      .from("shipment_reports")
      .delete()
      .eq("id", id);
    if (error) throw error;
  },

  async updateReportStatus(id, status) {
    console.log("Triggering updateReportStatus API", id, status);
    try {
      const { data, error } = await supabase
        .from("shipment_reports")
        .update({ status, updated_at: new Date().toISOString() })
        .eq("id", id)
        .select()
        .single();

      if (error) {
        console.error("Error updating report status:", error);
        throw error;
      }

      console.log("Successfully updated report status:", data);
      return data;
    } catch (error) {
      console.error("Unexpected error in updateReportStatus:", error);
      throw error;
    }
  },
};
