import { supabase } from "../lib/supabase";

export const CarrierService = {
  async getCarrierList(activeOnly = true) {
    const { data, error } = await supabase
      .from("carriers")
      .select("*")
      .eq("is_active", activeOnly)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
  },

  async getCarrierDetails(id) {
    const { data, error } = await supabase
      .from("carriers")
      .select("*")
      .eq("id", id)
      .single();

    if (error) throw error;
    return data;
  },

  async getAllCarrierDetails() {
    const { data, error } = await supabase.from("carriers").select("*");
    if (error) throw error;
    return data;
  },

  async deleteCarrier(id) {
    const { error } = await supabase.from("carriers").delete().eq("id", id);
    if (error) throw error;
  },

  async toggleCarrierActiveStatus({ id, isActive }) {
    const { data, error } = await supabase
      .from("carriers")
      .update({ is_active: !isActive })
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return data;
  },

  async insertCarrier(data) {
    const { data: newCarrier, error } = await supabase
      .from("carriers")
      .insert(data)
      .select()
      .single();

    if (error) throw error;
    return newCarrier;
  },

  async updateCarrier({ id, updatedFields }) {
    const { data: updatedCarrier, error } = await supabase
      .from("carriers")
      .update(updatedFields)
      .eq("id", id)
      .select()
      .single();

    if (error) throw error;
    return updatedCarrier;
  },

  async searchCarriers(searchTerm) {
    const { data, error } = await supabase
      .from("carriers")
      .select("*")
      .or(`name.ilike.%${searchTerm}%,email.ilike.%${searchTerm}%`)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
  },

  async bulkInsertCarriers(carriers) {
    const { data: insertedCarriers, error } = await supabase
      .from("carriers")
      .insert(carriers)
      .select();

    if (error) throw error;
    return insertedCarriers;
  },
};
