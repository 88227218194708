// @ts-nocheck
import MemoryCache from "../lib/cache/MemoryCache";
import StorageCache from "../lib/cache/StorageCache";
import { cacheConfig } from "../lib/cache/cache";

class CacheService {
  static instance = null;

  constructor() {
    this.memoryCache = new MemoryCache(cacheConfig.memory);
    this.storageCache = new StorageCache("app_cache", cacheConfig.storage);
  }

  static getInstance() {
    if (!CacheService.instance) {
      CacheService.instance = new CacheService();
    }
    return CacheService.instance;
  }

  async get(key) {
    // Try memory first
    const memoryValue = this.memoryCache.get(key);
    if (memoryValue) return memoryValue;

    // Try storage next
    const storageValue = this.storageCache.get(key);
    if (storageValue) {
      // Save to memory for next time
      this.memoryCache.set(key, storageValue);
      return storageValue;
    }

    return null;
  }

  set(key, value) {
    this.memoryCache.set(key, value);
    this.storageCache.set(key, value);
  }
}

export default CacheService;
