import { VerificationNode } from "./VerificationNode";
import { PhotoDocNode } from "./PhotoDocNode";
import { StatusUpdateNode } from "./StatusUpdateNode";
import { LocationNode } from "./LocationNode";
import { ConditionalNode } from "./ConditionalNode";
import { NotificationNode } from "./NotificationNode";
import { StartNode } from "./StartNode";
import { EndNode } from "./EndNode";

export const initialNodes = [
  {
    id: "start",
    type: "startNode",
    position: { x: 0, y: 0 },
    data: { label: "Start Shipment" },
  },
  {
    id: "end",
    type: "endNode",
    position: { x: 0, y: 400 },
    data: { label: "Complete Shipment" },
  },
];

export const nodeTypes = {
  startNode: StartNode,
  endNode: EndNode,
  verification: VerificationNode,
  photoDoc: PhotoDocNode,
  statusUpdate: StatusUpdateNode,
  location: LocationNode,
  conditional: ConditionalNode,
  notification: NotificationNode,
};
