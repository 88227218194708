// @ts-nocheck
import { useState, useEffect, useCallback } from "react";
import { supabase } from "../lib/supabase"; // Ensure this import is correct
import OCRService from "../api/OCRService";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { checkVerificationStatus } from "../api/VerificationService";
import { performVerificationOCR } from "../api/VerificationService";

export const useVerificationState = (shipmentId) => {
  const [verificationState, setVerificationState] = useState({
    personaComplete: false,
    personaSuccess: false,
    ocrComplete: false,
    ocrSuccess: false,
    progress: 0,
    isComplete: false,
    personaVerificationURL: null,
  });

  const fetchState = useCallback(async () => {
    try {
      console.log("Fetching verification state for shipment ID:", shipmentId);
      const { data, error } = await supabase
        .from("verifications")
        .select("*")
        .eq("shipment_id", shipmentId)
        .single();

      if (error) throw error;

      setVerificationState({
        personaComplete: data.persona_verification_complete,
        personaSuccess: data.state === "persona_verified",
        ocrComplete: data.ocr_verification_complete,
        ocrSuccess: data.state === "ocr_verified",
        progress: calculateProgress(data),
        isComplete: data.state === "completed",
        personaVerificationURL: data.persona_verification_url,
      });
    } catch (error) {
      console.error("Error fetching verification state:", error);
    }
  }, [shipmentId]);

  useEffect(() => {
    fetchState();
  }, [fetchState, shipmentId]);

  const updateVerificationState = useCallback(
    async (newState) => {
      try {
        const { error } = await supabase
          .from("verifications")
          .update(newState)
          .eq("shipment_id", shipmentId);

        if (error) throw error;

        setVerificationState((prevState) => ({ ...prevState, ...newState }));
        fetchState();
      } catch (error) {
        console.error("Error updating verification state:", error);
      }
    },
    [fetchState, shipmentId]
  );

  return { verificationState, updateVerificationState };
};

export const useImageCapture = () => {
  const [capturedImage, setCapturedImage] = useState(null); // Data URL
  const [capturedFile, setCapturedFile] = useState(null); // File object
  const [error, setError] = useState(null);

  const captureImage = (file) => {
    setError(null); // Reset error state

    if (!(file instanceof File)) {
      setError("Invalid file type. Please select an image file.");
      return;
    }

    // Store the original File object
    setCapturedFile(file);

    // Read the file as a Data URL for display
    const reader = new FileReader();
    reader.onload = (e) => {
      setCapturedImage(e.target.result);
    };
    reader.onerror = () => {
      setError("Failed to read the file. Please try again.");
    };
    reader.readAsDataURL(file);
  };

  const resetImage = () => {
    setCapturedImage(null);
    setCapturedFile(null);
    setError(null);
  };

  return { capturedImage, capturedFile, captureImage, resetImage, error };
};

export const startPersonaVerification = async (verificationState) => {
  try {
    console.log("Verification state:", verificationState);
    const oneTimeLink = verificationState.personaVerificationURL;

    if (!oneTimeLink) {
      throw new Error("Persona verification URL is not available");
    }

    console.log("Redirecting to:", oneTimeLink);
    window.open(oneTimeLink);
  } catch (error) {
    console.error("Error starting Persona verification:", error);
  }
};

export const useVerification = (shipmentId) => {
  return useQuery({
    queryKey: ["verification", shipmentId],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("verifications")
        .select("*")
        .eq("shipment_id", shipmentId)
        .single();

      if (error) throw error;
      return data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
};

export const useVerificationMutations = () => {
  const queryClient = useQueryClient();

  const updateVerification = useMutation({
    mutationFn: async ({ shipmentId, data }) => {
      console.log("Triggering updateVerification hook", shipmentId, data);
      try {
        const { data: updatedData, error } = await supabase
          .from("verifications")
          .update(data)
          .eq("shipment_id", shipmentId)
          .single();

        if (error) {
          console.error("Error updating verification:", error);
          throw error;
        }

        console.log("Successfully updated verification:", updatedData);
        return updatedData;
      } catch (error) {
        console.error("Unexpected error in updateVerification:", error);
        throw error;
      }
    },
    onSuccess: (data, variables) => {
      console.log("Verification update successful, invalidating queries");
      queryClient.invalidateQueries(["verification", variables.shipmentId]);
    },
    onError: (error, variables) => {
      console.error("Mutation error in updateVerification:", error);
      console.log(
        "Failed update attempt for shipment ID:",
        variables.shipmentId
      );
    },
  });

  const processOCR = useMutation({
    mutationFn: async ({ shipmentId, file }) => {
      const imageUrl = await OCRService.uploadImage(file, shipmentId); // Assuming you have this method

      // Call the `performVerificationOCR` function from the service
      const result = await performVerificationOCR(shipmentId, file, imageUrl);

      return result;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["verification", variables.shipmentId]);
    },
  });

  const checkStatus = useMutation({
    mutationFn: async ({ shipmentId }) => {
      const result = await checkVerificationStatus(shipmentId);
      console.log("Check status result", result);

      if (result.status === "completed") {
        const { data, error } = await supabase
          .from("verifications")
          .update({ persona_verification_complete: true })
          .eq("shipment_id", shipmentId)
          .single();

        if (error) throw error;

        console.log("Updated verification", data);

        return { ...result, updatedVerification: data };
      }

      return result;
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["verification", variables.shipmentId]);
    },
  });

  return { updateVerification, processOCR, checkStatus };
};

// Helper function to calculate progress
function calculateProgress(data) {
  let progress = 0;
  if (data.persona_verification_complete) progress += 50;
  if (data.ocr_verification_complete) progress += 50;
  return progress;
}

export const useGetAllVerifications = () => {
  return useQuery({
    queryKey: ["verifications"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("verifications")
        .select("*")
        .order("created_at", { ascending: false });

      if (error) throw error;
      return data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes
  });
};
