// @ts-nocheck
import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  FiChevronDown,
  FiChevronUp,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const TableHeader = () => (
  <thead className="bg-zinc-100 text-xs uppercase text-gray-700">
    <tr>
      {[
        "ID",
        "Name",
        "Address",
        "City",
        "State",
        "Zip Code",
        "Country",
        "Phone Number",
        "Email",
        "Website",
        "Actions",
      ].map((header) => (
        <th
          key={header}
          className="px-4 py-2 text-left font-normal text-zinc-600"
        >
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

const TableCell = ({ children, icon: Icon }) => (
  <td className="px-4 py-2 text-sm">
    <div className="flex items-center space-x-2">
      {Icon && <Icon size={16} className="text-gray-700" />}
      <span className="text-gray-600">{children}</span>
    </div>
  </td>
);

const ExpandButton = ({ isExpanded, onClick }) => (
  <button
    onClick={onClick}
    className="flex items-center space-x-1 text-blue-600 hover:text-blue-800"
  >
    {isExpanded ? <FiChevronUp size={16} /> : <FiChevronDown size={16} />}
    <span className="sr-only">{isExpanded ? "Hide" : "View"} Details</span>
  </button>
);

const RowDetails = ({ row }) => {
  const navigate = useNavigate();

  const handleVerification = () => {
    navigate(`/verification/${row.id}`);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="bg-gray-100"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-50 p-4 text-sm">
        <div>
          <h4 className="font-semibold text-slate-600">Carrier Details</h4>
          <p className="text-slate-500">Name: {row.name}</p>
          <p className="text-slate-500">Address: {row.address}</p>
          <p className="text-slate-500">City: {row.city}</p>
          <p className="text-slate-500">State: {row.state}</p>
          <p className="text-slate-500">Zip Code: {row.zip_code}</p>
          <p className="text-slate-500">Country: {row.country}</p>
          <p className="text-slate-500">Phone Number: {row.phone_number}</p>
          <p className="text-slate-500">Email: {row.email}</p>
          <p className="text-slate-500">Website: {row.website || "N/A"}</p>
        </div>
        <div>
          <h4 className="font-semibold text-slate-600">
            Additional Information
          </h4>
          <p className="text-slate-500">
            DOT Number: {row.dot_number || "N/A"}
          </p>
          <p className="text-slate-500">MC Number: {row.mc_number || "N/A"}</p>
          <p className="text-slate-500">Tax ID: {row.tax_id || "N/A"}</p>
          <p className="text-slate-500">
            Number of Trucks: {row.number_of_trucks || "N/A"}
          </p>
          <p className="text-slate-500">
            Number of Drivers: {row.number_of_drivers || "N/A"}
          </p>
          <p className="text-slate-500">
            Safety Rating: {row.safety_rating || "N/A"}
          </p>
          <p className="text-slate-500">
            Insurance Provider: {row.insurance_provider || "N/A"}
          </p>
          <p className="text-slate-500">
            Insurance Policy Number: {row.insurance_policy_number || "N/A"}
          </p>
          <p className="text-slate-500">
            Is Active: {row.is_active ? "Yes" : "No"}
          </p>
        </div>
        <div className="col-span-2">
          <button
            onClick={handleVerification}
            className="px-4 py-2 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 transition-colors"
          >
            Issue Verification
          </button>
        </div>
      </div>
    </motion.div>
  );
};

const TableRow = ({ row, index, expandedRow, setExpandedRow }) => {
  const isExpanded = expandedRow === index;

  return (
    <>
      <tr className="border-b border-gray-200 hover:bg-gray-50 group">
        <TableCell>{row.id}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.address}</TableCell>
        <TableCell>{row.city}</TableCell>
        <TableCell>{row.state}</TableCell>
        <TableCell>{row.zip_code}</TableCell>
        <TableCell>{row.country}</TableCell>
        <TableCell>{row.phone_number}</TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.website || "N/A"}</TableCell>
        <td className="px-4 py-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <ExpandButton
            isExpanded={isExpanded}
            onClick={() => setExpandedRow(isExpanded ? null : index)}
          />
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan="10">
            <RowDetails row={row} />
          </td>
        </tr>
      )}
    </>
  );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div className="flex flex-1 justify-between sm:hidden">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        Previous
      </button>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        Next
      </button>
    </div>
    <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p className="text-sm text-gray-700">
          Showing page <span className="font-medium">{currentPage}</span> of{" "}
          <span className="font-medium">{totalPages}</span>
        </p>
      </div>
      <div>
        <nav
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Previous</span>
            <FiChevronLeft className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
          >
            <span className="sr-only">Next</span>
            <FiChevronRight className="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
);

const DataTable = ({ data, currentPage, totalPages, onPageChange, limit }) => {
  const [expandedRow, setExpandedRow] = useState(null);

  // Calculate the start and end indices for slicing the data
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedData = data.slice(startIndex, endIndex);
  return (
    <div className="bg-white rounded-lg shadow">
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <TableHeader />
          <tbody>
            {paginatedData.map((row, index) => (
              <TableRow
                key={row._id} // Ensure _id exists in your data
                row={row}
                index={index}
                expandedRow={expandedRow}
                setExpandedRow={setExpandedRow}
              />
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default DataTable;
