import { MarkerType } from "reactflow";

export const initialEdges = [];

// Define edge styles separately
export const edgeStyles = {
  success: {
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#10B981" },
  },
  failure: {
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#EF4444" },
  },
  default: {
    type: "smoothstep",
    markerEnd: { type: MarkerType.ArrowClosed },
    style: { stroke: "#6B7280" },
  },
};
