// @ts-nocheck
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { CargoOwnerService } from "../api/CargoOwnerService";

export const useCargoOwnerById = (id) => {
  return useQuery({
    queryKey: ["cargoOwner", id],
    queryFn: () => CargoOwnerService.getCargoOwnerById(id),
    enabled: !!id,
  });
};

export const useCargoOwnerByShipment = (loadId) => {
  return useQuery({
    queryKey: ["cargoOwnerByShipment", loadId],
    queryFn: () => CargoOwnerService.getCargoOwnerByShipment(loadId),
    enabled: !!loadId,
  });
};

export const useCargoOwnerByCompany = (companyId) => {
  return useQuery({
    queryKey: ["cargoOwner", companyId],
    queryFn: () => CargoOwnerService.getCargoOwnerByCompany(companyId),
    enabled: !!companyId,
  });
};

export const useCargoOwners = (shipments) => {
  const cargoOwnerIds = shipments
    ? [...new Set(shipments.map((s) => s.cargo_owner_id).filter(Boolean))]
    : [];

  return useQuery({
    queryKey: ["cargoOwners", cargoOwnerIds],
    queryFn: () => CargoOwnerService.getCargoOwners(cargoOwnerIds),
    enabled: cargoOwnerIds.length > 0,
  });
};

export const useCargoOwnerList = () => {
  return useQuery({
    queryKey: ["cargoOwnerList"],
    queryFn: async () => {
      console.log("Starting cargo owners fetch");
      try {
        const response = await CargoOwnerService.getAllCargoOwners();
        console.log("Cargo owners API response:", response);
        return response;
      } catch (error) {
        console.error("Error in useCargoOwnerList:", error);
        throw error;
      }
    },
    retry: false,
    onError: (error) => {
      console.error("Query error in useCargoOwnerList:", error);
    },
    onSuccess: (data) => {
      console.log("Successfully fetched cargo owners:", data);
    },
  });
};

export const useCreateCargoOwner = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      console.log("Creating cargo owner in hook", data);
      return await CargoOwnerService.createCargoOwner(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cargoOwnerList"] });
    },
  });
};
