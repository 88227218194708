// @ts-nocheck
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { PhotoService } from "../api/PhotosService";
import { useState, useEffect } from "react";
import { supabase } from "../lib/supabase";

/**
 * Hook for managing photo data with optimized loading and caching
 * @param {string} loadId - Optional load ID to filter photos
 * @returns {Object} Photo data and loading state
 */
export const usePhotos = (loadId) => {
  const [photos, setPhotos] = useState([]);
  const [groupedPhotos, setGroupedPhotos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch photos
  useEffect(() => {
    // Early return if no loadId, but keep loading state true
    if (!loadId) {
      setPhotos([]);
      setGroupedPhotos({});
      setIsLoading(true);
      return;
    }

    const fetchPhotos = async () => {
      try {
        setIsLoading(true);
        setError(null);
        console.log("Fetching photos for loadId:", loadId);
        const photoData = await PhotoService.getPhotos({ loadId });

        // Fetch signed URLs in batches
        const batchSize = 5;
        const processedPhotos = [];

        for (let i = 0; i < photoData.length; i += batchSize) {
          const batch = photoData.slice(i, i + batchSize);

          // Fetch signed URLs for the batch
          await Promise.all(
            batch.map(async (photo) => {
              try {
                const url = await PhotoService.getPhotoUrl(photo.photo_url);
                processedPhotos.push({ ...photo, url });
              } catch (err) {
                console.error(`Error fetching URL for photo ${photo.id}:`, err);
                processedPhotos.push({ ...photo, url: null });
              }
            })
          );
        }

        setPhotos(processedPhotos);
      } catch (err) {
        setError(err);
        console.error("Error fetching photos:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPhotos();
  }, [loadId]);

  // Group photos by stage
  useEffect(() => {
    if (photos.length > 0) {
      const grouped = photos.reduce((acc, photo) => {
        const stage = photo.stage_name || "Uncategorized";
        if (!acc[stage]) acc[stage] = [];
        acc[stage].push(photo);
        return acc;
      }, {});
      setGroupedPhotos(grouped);
    } else {
      setGroupedPhotos({});
    }
  }, [photos]);

  return {
    photos,
    setPhotos,
    groupedPhotos,
    isLoading,
    error,
  };
};

/**
 * Hook for uploading a photo with progress tracking
 */
export const useUploadPhoto = () => {
  const queryClient = useQueryClient();
  const [uploadProgress, setUploadProgress] = useState(0);

  const mutation = useMutation({
    mutationFn: PhotoService.uploadPhoto,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["photos"] });
      setUploadProgress(0);
    },
    onError: (error) => {
      console.error("Error uploading photo:", error);
      setUploadProgress(0);
    },
  });

  return {
    ...mutation,
    uploadProgress,
    setUploadProgress,
  };
};

/**
 * Hook for getting a single photo's details with caching
 */
export const usePhotoDetails = (loadId, side) => {
  return useQuery({
    queryKey: ["photoDetails", loadId, side],
    queryFn: () => PhotoService.getPhotoDetails(loadId, side),
    enabled: !!loadId && !!side,
    staleTime: 1000 * 60 * 30, // Consider data fresh for 30 minutes
    cacheTime: 1000 * 60 * 60, // Keep in cache for 1 hour
  });
};

/**
 * Hook for downloading a photo using the public URL.
 * @returns {UseMutationResult} Mutation result object for downloading a photo.
 */
export const useDownloadPhoto = () => {
  return useMutation({
    mutationFn: PhotoService.downloadPhoto,
    onSuccess: () => {
      console.log("Photo downloaded successfully");
    },
    onError: (error) => {
      console.error("Error downloading photo:", error);
    },
  });
};

export const useSignedUrl = (filePath) => {
  const [signedUrl, setSignedUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getSignedUrl = async () => {
      if (!filePath) {
        setIsLoading(false);
        return;
      }

      try {
        const { data, error } = await supabase.storage
          .from("loadPhotos")
          .createSignedUrl(filePath, 3600); // 1 hour expiry

        if (error) throw error;
        setSignedUrl(data.signedUrl);
      } catch (err) {
        console.error("Error getting signed URL:", err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getSignedUrl();
  }, [filePath]);

  return { signedUrl, error, isLoading };
};
