import React from "react";
import { BaseNode } from "./BaseNode";

export const VerificationNode = ({ data, isConnectable }) => {
  return (
    <BaseNode data={data} isConnectable={isConnectable} type="verification">
      <div className="mt-2 text-sm text-gray-600">
        <select
          className="w-full p-1 rounded border border-gray-300"
          value={data.verificationType}
          onChange={(e) => data.onChange?.("verificationType", e.target.value)}
        >
          <option value="identity">Identity Verification</option>
          <option value="document">Document Verification</option>
          <option value="photo">Photo Verification</option>
        </select>
        {data.verificationType === "photo" && (
          <div className="mt-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={data.requireAllSides}
                onChange={(e) =>
                  data.onChange?.("requireAllSides", e.target.checked)
                }
                className="mr-2"
              />
              Require all sides
            </label>
          </div>
        )}
      </div>
    </BaseNode>
  );
};
