// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../Portal/Header";
import Filter from "./ShipmentFilter";
import SummaryStats from "./ShipmentStats";
import DataTable from "./ShipmentTable";
import { useShipments } from "../../hooks/shipments";
import { supabase } from "../../lib/supabase"; // Make sure this import exists

const processPhotos = async (photoData) => {
  if (!photoData?.photos) return null;

  try {
    // Create a new object with signed URLs
    const processedPhotos = {};

    // Process each photo
    await Promise.all(
      Object.entries(photoData.photos).map(async ([side, filePath]) => {
        try {
          // Extract photo_url from the filePath object
          const path =
            typeof filePath === "object" ? filePath.photo_url : filePath;

          const { data, error } = await supabase.storage
            .from("loadPhotos")
            .createSignedUrl(path, 3600); // 1 hour expiry

          if (error) {
            console.error(`Error getting signed URL for ${side}:`, error);
            return;
          }

          processedPhotos[side] = data.signedUrl;
        } catch (error) {
          console.error(`Error processing ${side} photo:`, error);
        }
      })
    );

    // Return the processed photo data with signed URLs
    return {
      photos: processedPhotos,
      metadata: photoData.metadata,
    };
  } catch (error) {
    console.error("Error processing photos:", error);
    return null;
  }
};

const ShipmentDashboardContent = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const {
    data: shipmentsData,
    isLoading,
    error,
  } = useShipments(page, limit, filters);

  // Remove client-side filtering logic since it's now handled by the backend
  const totalPages = Math.ceil((shipmentsData?.total || 0) / limit);

  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  return (
    <div className="bg-white text-gray-300">
      <Header companyName="Shipments" url="/dashboard/add-shipment" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <SummaryStats data={shipmentsData?.shipments || []} />
        <div className="mt-8">
          <DataTable
            data={shipmentsData?.shipments || []}
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const ShipmentDashboard = () => <ShipmentDashboardContent />;

export default ShipmentDashboard;
