// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { supabase } from "../lib/supabase";
import Toast from "../pages/Components/Toast";

const ProtectedRoute = () => {
  const [cargoOwnerId, setCargoOwnerId] = useState(null);
  const { session, loading } = useAuth();

  useEffect(() => {
    const checkExternalUser = async () => {
      // Only proceed with the check if we have a session and it's an external user
      if (session?.user?.user_metadata?.role === "external_user") {
        try {
          const { data, error } = await supabase
            .from("external_users")
            .select("*")
            .eq("user_id", session.user.id);

          if (error) {
            Toast.error(error.message);
            return;
          }

          if (data && data.length > 0) {
            setCargoOwnerId(data[0].cargo_owner_id);
          }
        } catch (error) {
          Toast.error("Failed to check external user status");
        }
      }
    };

    checkExternalUser();
  }, [session]);

  if (loading || session === undefined) {
    return <div>Loading...</div>;
  }

  if (!session?.access_token) {
    return <Navigate to="/signin" replace />;
  }

  const currentTime = Math.floor(Date.now() / 1000);
  if (session.expires_at && currentTime >= session.expires_at) {
    return <Navigate to="/signin" replace />;
  }

  // Handle external user navigation after all other checks
  if (cargoOwnerId) {
    return <Navigate to={`/external-user/${cargoOwnerId}`} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
