// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../Portal/Header";
import Filter from "./CarriersFilter";
import DataTable from "./CarriersTable";
import { useCarrierList } from "../../hooks/carriers";

// FleetDashboard.js
const CarrierDashboard = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const limit = 10;

  const { data: carriersData, isLoading, error } = useCarrierList();
  console.log(carriersData);

  useEffect(() => {
    if (carriersData) {
      const totalCarriers = carriersData.length;
      console.log(totalCarriers);
      setTotalPages(Math.ceil(totalCarriers / limit));
    }
  }, [carriersData, limit, filters]);

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (!carriersData || carriersData.length === 0) {
    return (
      <div className="rounded-lg shadow-md p-8 text-center">
        <p className="text-xl text-gray-400">No carriers available</p>
      </div>
    );
  }

  const handlePageChange = (newPage) => {
    console.log("Triggered");
    setPage(newPage);
  };

  return (
    <div className="bg-white text-gray-300">
      <Header companyName="Carriers" url="/dashboard/add-carrier" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <div className="mt-8">
          <DataTable
            data={carriersData}
            currentPage={page}
            totalPages={totalPages} // Use the locally calculated totalPages
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const CarrierDashboardContent = () => <CarrierDashboard />;

export default CarrierDashboardContent;
