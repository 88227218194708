// @ts-nocheck
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CarrierService } from "../api/CarrierService";

// Fetch the list of carriers, optionally filtering by active status
export const useCarrierList = () => {
  return useQuery({
    queryKey: ["carriers"],
    queryFn: () => CarrierService.getAllCarrierDetails(),
  });
};

// Fetch carrier details by carrier ID
export const useCarrierDetails = (id) => {
  return useQuery({
    queryKey: ["carriers", id],
    queryFn: () => CarrierService.getCarrierDetails(id),
    enabled: !!id,
  });
};

export const useCreateCarrier = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      console.log("Creating carrier in hook", data);
      return await CarrierService.insertCarrier(data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["carriers"] });
    },
  });
};

// Carrier mutations
export const useCarrierMutations = () => {
  const queryClient = useQueryClient();

  const invalidateCarriers = async (id) => {
    await queryClient.invalidateQueries({ queryKey: ["carriers"] });
    if (id) await queryClient.invalidateQueries({ queryKey: ["carriers", id] });
  };

  const deleteCarrier = useMutation({
    mutationFn: CarrierService.deleteCarrier,
    onSuccess: (_, id) => invalidateCarriers(id),
  });

  const toggleCarrierActiveStatus = useMutation({
    mutationFn: CarrierService.toggleCarrierActiveStatus,
    onSuccess: (_, { id }) => invalidateCarriers(id),
  });

  const insertCarrier = useMutation({
    mutationFn: CarrierService.insertCarrier,
    onSuccess: () => invalidateCarriers(),
  });

  const updateCarrier = useMutation({
    mutationFn: CarrierService.updateCarrier,
    onSuccess: (_, { id }) => invalidateCarriers(id),
  });

  return {
    deleteCarrier,
    toggleCarrierActiveStatus,
    insertCarrier,
    updateCarrier,
  };
};

// Search carriers by name or email
export const useCarrierSearch = (searchTerm) => {
  return useQuery({
    queryKey: ["carriers", "search", searchTerm],
    queryFn: () => CarrierService.searchCarriers(searchTerm),
    enabled: !!searchTerm,
  });
};

// Bulk insert carrier records
export const useBulkInsertCarriers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: CarrierService.bulkInsertCarriers,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["carriers"] }),
  });
};
