import React from "react";

const OCRResultDisplay = ({ result, onConfirm }) => {
  const { verificationResult, ocrResult } = result;
  console.log("OCR Result Display", result);
  console.log("Verification Result", verificationResult);
  console.log("OCR Result", ocrResult);

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">OCR Verification Result</h2>
      <div className="mb-4">
        <p>Expected Shipment ID: {verificationResult.expected}</p>
        <p
          className={
            verificationResult.match ? "text-green-600" : "text-red-600"
          }
        >
          {verificationResult.match ? "Match" : "No Match"}
        </p>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-semibold">OCR Text:</h3>
        <p className="text-sm">{ocrResult.ocrText}</p>
      </div>
      <button
        onClick={onConfirm}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        disabled={!verificationResult.match}
      >
        {verificationResult.match ? "Confirm" : "Retry"}
      </button>
    </div>
  );
};

export default OCRResultDisplay;
