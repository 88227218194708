// @ts-nocheck
import { supabase } from "../lib/supabase";

const SERVER_URL =
  process.env.REACT_APP_URL_BASE_FOR_SERVER || "http://localhost:3000";

export const NotificationService = {
  sendEmail: async (to, from, subject, html) => {
    try {
      const { data, error } = await supabase.functions.invoke("send-email", {
        body: { to, from, subject, html },
      });

      if (error) throw error;

      return data;
    } catch (error) {
      console.error("Error sending email:", error);
      throw error;
    }
  },
  sendMessage: async (body, from, to) => {
    try {
      const { data, error } = await supabase.functions.invoke("send-message", {
        body: { body, from, to },
      });

      if (error) throw error;

      return data;
    } catch (error) {
      console.error("Error sending message:", error);
      throw error;
    }
  },

  sendSMS: async (body, to, from) => {
    return NotificationService.sendMessage(body, from, to);
  },

  sendVerificationSMS: async (verification) => {
    if (!SERVER_URL) {
      throw new Error("SERVER_URL is not set");
    }
    const body = `Please complete your verification at: ${SERVER_URL}/verify/${verification.shipment_id}`;
    const to = verification.phone_number;
    const from = process.env.REACT_APP_TWILIO_PHONE_NUMBER;
    return NotificationService.sendSMS(body, to, from);
  },
};

// Add this line at the end of the file
export const { sendEmail, sendSMS, sendVerificationSMS } = NotificationService;
