// @ts-nocheck
import Persona from "persona";

const createPersonaClient = (referenceId, onCompleteCallback) => {
  console.log("Creating Persona Client");
  if (!referenceId) {
    console.error("Reference ID is required");
    return null;
  }
  if (!process.env.REACT_APP_PERSONA_TEMPLATE_ID) {
    console.error("Persona Template ID is required");
    return null;
  }
  if (!process.env.REACT_APP_PERSONA_ENVIRONMENT_ID) {
    console.error("Persona Environment ID is required");
    return null;
  }

  const client = new Persona.Client({
    templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
    referenceId: referenceId,
    environmentId: process.env.REACT_APP_PERSONA_ENVIRONMENT_ID,
    onReady: () => client.open(),
    onComplete: (data) => {
      // Call the passed callback if provided
      if (onCompleteCallback) {
        onCompleteCallback(data);
      }
      console.log(`Sending finished inquiry ${data.inquiryId} to backend`);
    },
    onCancel: ({ inquiryId, sessionToken }) => console.log("onCancel"),
    onError: (error) => console.log(error),
  });

  return client;
};

export default createPersonaClient;
