// @ts-nocheck
import React, { useState, useEffect } from "react";
import Header from "../Portal/Header";
import Filter from "./ReportFilter";
import SummaryStats from "./ReportStats";
import DataTable from "./ReportTable";
import { useReports } from "../../hooks/reports";

// FleetDashboard.js
const ReportDashboardContent = () => {
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const { data: reportsData, isLoading, error } = useReports();

  useEffect(() => {
    if (reportsData) {
      const totalReports = reportsData.reports.length;
      setTotalPages(Math.ceil(totalReports / limit));
    }
  }, [reportsData, limit]); // Recalculate totalPages when shipmentsData or limit changes

  if (isLoading)
    return <div className="text-center text-gray-300 mt-20">Loading...</div>;
  if (error)
    return (
      <div className="text-center text-red-500 mt-20">
        Error: {error.message}
      </div>
    );

  if (!reportsData || reportsData.reports.length === 0) {
    return (
      <div className="rounded-lg shadow-md p-8 text-center">
        <p className="text-xl text-gray-400">No reports available</p>
      </div>
    );
  }

  const handlePageChange = (newPage) => {
    console.log("Triggered");
    setPage(newPage);
  };

  return (
    <div className="bg-white text-gray-300">
      <Header companyName="Reports Management" url="/dashboard/add-report" />
      <div className="mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="w-1/2">
          <Filter onFilterChange={setFilters} />
        </div>
        <SummaryStats data={reportsData?.reports || []} />
        <div className="mt-8">
          <DataTable
            data={reportsData?.reports || []}
            currentPage={page}
            totalPages={totalPages} // Use the locally calculated totalPages
            onPageChange={handlePageChange}
            limit={limit}
          />
        </div>
      </div>
    </div>
  );
};

const ReportDashboard = () => <ReportDashboardContent />;

export default ReportDashboard;
