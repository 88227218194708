// @ts-nocheck
import { supabase } from "../lib/supabase";

const OCRService = {
  uploadImage: async (imageFile, shipmentId) => {
    try {
      // File type validation
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(imageFile.type)) {
        throw new Error(
          "Invalid file type. Only JPEG, PNG, and GIF are allowed."
        );
      }

      // File size validation
      const MAX_FILE_SIZE = 4 * 1024 * 1024; // 4MB instead of 5MB
      if (imageFile.size > MAX_FILE_SIZE) {
        throw new Error("File size exceeds the limit of 5MB.");
      }

      const fileExt = imageFile.name.split(".").pop();
      const fileName = `${shipmentId}_${Date.now()}.${fileExt}`;
      const { data: uploadData, error: uploadError } = await supabase.storage
        .from("documentImages")
        .upload(fileName, imageFile);

      if (uploadError) throw uploadError;

      // Get the signed URL
      const { data: urlData, error: urlError } = await supabase.storage
        .from("documentImages")
        .createSignedUrl(fileName, 60 * 60); // URL expires in 1 hour (3600 seconds)

      if (urlError) throw urlError;

      const signedUrl = urlData.signedUrl;

      if (!signedUrl) {
        throw new Error("Failed to get signed URL for uploaded image");
      }

      // Update the shipments table with the document_photo_url
      const { error: updateError } = await supabase
        .from("shipments")
        .upsert({ document_photo_url: signedUrl })
        .eq("load_id", shipmentId);

      if (updateError) throw updateError;

      return {
        signedUrl,
        fileName,
        filePath: uploadData.path,
        fileSize: imageFile.size,
      };
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  },

  processImage: async (imageUrl, shipmentId) => {
    try {
      const { data, error } = await supabase.functions.invoke("ocr", {
        body: JSON.stringify({ imageUrl: imageUrl.signedUrl, shipmentId }),
      });

      if (error) {
        throw new Error(`Error invoking OCR function: ${error.message}`);
      }

      // Check for Eden AI specific errors
      if (data?.microsoft?.error) {
        const msError = data.microsoft.error;
        if (msError.type === "ProviderInvalidInputFileSizeError") {
          throw new Error(
            "The image file is too large for OCR processing. Please try with a smaller image (try compressing the image or reducing its dimensions)."
          );
        }
        throw new Error(`OCR processing failed: ${msError.message}`);
      }

      return data;
    } catch (error) {
      console.error("Error processing OCR:", error);
      throw error;
    }
  },
};

export default OCRService;
