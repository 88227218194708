import React from "react";
import { Outlet } from 'react-router-dom'
import Sidebar from '../Components/Sidebar';

const DashboardLayout = () => {
    return (
        <div className='flex h-screen bg-gray-900 text-white'>
            <Sidebar/>
            <div className='flex flex-col flex-1'>
                <main className="flex-1 overflow-x-hidden overflow-y-auto">
                    <Outlet/>
                </main>
            </div>
        </div>
    )
}

export default DashboardLayout;