import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase"; // Import supabase client

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check authentication status when component mounts
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setIsLoggedIn(!!user);
    } catch (error) {
      console.error("Error checking auth status:", error);
      setIsLoggedIn(false);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <nav
      className={`shadow-md w-full ${
        window.location.pathname === "/" ? "fixed top-0 z-50" : ""
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/">
              <img className="h-8 w-auto" src="/static/logo.png" alt="Logo" />
            </Link>
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-4">
            {isLoggedIn ? (
              <>
                <Link
                  to="mailto:support@indemni.com"
                  className=" text-white hover:bg-slate-700 px-4 py-2 rounded-md text-sm font-medium"
                >
                  Contact for Help
                </Link>
                <Link
                  to="/dashboard"
                  className=" text-white hover:bg-slate-700 px-4 py-2 rounded-md text-sm font-medium"
                >
                  Dashboard
                </Link>
                <button
                  onClick={handleLogout}
                  className="bg-slate-900 text-white hover:bg-slate-700 px-4 py-2 rounded-md text-sm font-medium"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/signup"
                  className="bg-slate-900 text-white hover:bg-slate-700 px-4 py-2 rounded-md text-sm font-medium"
                >
                  Don't have an account?
                </Link>
                <Link
                  to="/signin"
                  className="bg-white text-slate-900 hover:bg-slate-100 px-4 py-2 rounded-md text-sm font-medium border border-slate-200"
                >
                  Sign In
                </Link>
              </>
            )}
          </div>

          <div className="flex items-center sm:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-slate-400 hover:text-slate-500 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              {/* Icon for menu button */}
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      {isMenuOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {isLoggedIn ? (
              <>
                <a
                  href="mailto:support@indemni.com"
                  className="bg-white text-slate-900 hover:bg-slate-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Contact for Help
                </a>
                <button
                  onClick={handleLogout}
                  className="w-full text-left bg-white text-slate-900 hover:bg-slate-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/signin"
                  className="bg-white text-slate-900 hover:bg-slate-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Sign In
                </Link>
                <Link
                  to="/signup"
                  className="bg-white text-slate-900 hover:bg-slate-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  Don't have an account?
                </Link>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
}
