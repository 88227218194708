// @ts-nocheck
import React, { useMemo } from "react";
import { useShipments } from "../../hooks/shipments";
import { Card, CardContent } from "../Components/Card";
import { Loader2Icon } from "lucide-react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Toast from "../Components/Toast";
import { useParams } from "react-router-dom";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Analytics = () => {
  const { id: cargoOwnerId } = useParams();
  const {
    data: shipments,
    isLoading,
    error,
  } = useShipments(1, 1000, {
    orderBy: "created_at",
    orderDirection: "desc",
    cargoOwnerId,
  });

  // Prepare data for charts
  const analytics = useMemo(() => {
    if (!shipments?.shipments) return null;

    // Status distribution
    const statusCount = shipments.shipments.reduce((acc, shipment) => {
      acc[shipment.status] = (acc[shipment.status] || 0) + 1;
      return acc;
    }, {});

    const statusData = Object.entries(statusCount).map(([name, value]) => ({
      name,
      value,
    }));

    // Monthly trends
    const monthlyData = shipments.shipments.reduce((acc, shipment) => {
      const month = new Date(shipment.created_at).toLocaleString("default", {
        month: "short",
      });
      if (!acc[month]) {
        acc[month] = { month, total: 0, completed: 0, in_transit: 0 };
      }
      acc[month].total += 1;
      acc[month][shipment.status] = (acc[month][shipment.status] || 0) + 1;
      return acc;
    }, {});

    return {
      statusDistribution: statusData,
      monthlyTrends: Object.values(monthlyData),
    };
  }, [shipments]);

  if (error) {
    return <Toast type="error" message={error.message} />;
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Status Distribution */}
        <Card>
          <CardContent>
            <h2 className="text-lg font-semibold mb-4 py-6">
              Shipment Status Distribution
            </h2>
            {isLoading ? (
              <div className="flex justify-center py-8">
                <Loader2Icon className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={analytics?.statusDistribution}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percent }) =>
                        `${name} (${(percent * 100).toFixed(0)}%)`
                      }
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {analytics?.statusDistribution.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Monthly Trends */}
        <Card>
          <CardContent>
            <h2 className="text-lg font-semibold mb-4 py-6">
              Monthly Shipment Trends
            </h2>
            {isLoading ? (
              <div className="flex justify-center py-8">
                <Loader2Icon className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={analytics?.monthlyTrends}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="total"
                      stroke="#8884d8"
                      name="Total Shipments"
                    />
                    <Line
                      type="monotone"
                      dataKey="completed"
                      stroke="#82ca9d"
                      name="Completed"
                    />
                    <Line
                      type="monotone"
                      dataKey="in_transit"
                      stroke="#ffc658"
                      name="In Transit"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Performance Metrics */}
        <Card>
          <CardContent>
            <h2 className="text-lg font-semibold mb-4 py-6">Completion Rate</h2>
            {isLoading ? (
              <div className="flex justify-center py-8">
                <Loader2Icon className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={analytics?.monthlyTrends}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="completed"
                      fill="#82ca9d"
                      name="Completed Shipments"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Key Metrics Summary */}
        <Card>
          <CardContent>
            <h2 className="text-lg font-semibold mb-4 py-6">Key Metrics</h2>
            {isLoading ? (
              <div className="flex justify-center py-8">
                <Loader2Icon className="w-8 h-8 animate-spin" />
              </div>
            ) : (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-600">Completion Rate</p>
                    <p className="text-2xl font-semibold">
                      {analytics?.statusDistribution.find(
                        (s) => s.name === "completed"
                      )?.value || 0}
                      %
                    </p>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-600">
                      Average Transit Time
                    </p>
                    <p className="text-2xl font-semibold">3.2 days</p>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-600">On-Time Delivery</p>
                    <p className="text-2xl font-semibold">94%</p>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <p className="text-sm text-gray-600">Active Shipments</p>
                    <p className="text-2xl font-semibold">
                      {analytics?.statusDistribution.find(
                        (s) => s.name === "in_transit"
                      )?.value || 0}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Analytics;
