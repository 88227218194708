import React from "react";
import DriverVerificationForm from "./DriverVerificationForm";
import ScheduledDrivers from "./ScheduledDrivers";

const VerificationForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row gap-10 p-10">
      <DriverVerificationForm />
      <ScheduledDrivers />
    </div>
  );
};

export default VerificationForm;
