// @ts-nocheck
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ReportService } from "../api/ReportService";

export const useReports = (page = 1, limit = 10) => {
  return useQuery({
    queryKey: ["reports", page, limit],
    queryFn: () => ReportService.getReports(page, limit),
    keepPreviousData: true,
  });
};

export const useReport = (id) => {
  return useQuery({
    queryKey: ["report", id],
    queryFn: () => ReportService.getReport(id),
    enabled: !!id,
  });
};

export const useReportMutations = () => {
  const queryClient = useQueryClient();

  const invalidateReports = async (id) => {
    await queryClient.invalidateQueries({ queryKey: ["reports"] });
    if (id) await queryClient.invalidateQueries({ queryKey: ["report", id] });
  };

  const createReport = useMutation({
    mutationFn: ReportService.createReport,
    onSuccess: () => invalidateReports(),
  });

  const updateReport = useMutation({
    mutationFn: ({ id, reportData }) =>
      ReportService.updateReport(id, reportData),
    onSuccess: (_, { id }) => invalidateReports(id),
  });

  const deleteReport = useMutation({
    mutationFn: ReportService.deleteReport,
    onSuccess: (_, id) => invalidateReports(id),
  });

  const updateReportStatus = useMutation({
    mutationFn: ({ id, status }) => {
      console.log("Triggering updateReportStatus hook", id, status);
      return ReportService.updateReportStatus(id, status);
    },
    onSuccess: (_, { id }) => invalidateReports(id),
  });

  return {
    createReport,
    updateReport,
    deleteReport,
    updateReportStatus,
  };
};
