// @ts-nocheck
import React, { useState, useEffect } from "react";
import InputField from "../../Components/InputField";
import { useCustomerCompanyId } from "../../../hooks/customers";
import { useGetUserSession } from "../../../hooks/users";
import { useDriverMutations } from "../../../hooks/drivers";

const AddDriver = () => {
  const { session, loading } = useGetUserSession();
  const { data: companyId } = useCustomerCompanyId(session?.user?.id);
  const { createDriver } = useDriverMutations();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone_number: "",
    email: "",
    driver_license_number: "",
    driver_license_state: "",
    driver_license_expiration_date: "",
    driver_license_class: "",
    company_id: companyId || null,
    employment_start_date: "",
    employment_status: "Active",
    is_verified: false,
  });

  useEffect(() => {
    if (companyId) {
      setFormData((prevData) => ({ ...prevData, company_id: companyId }));
    }
  }, [companyId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form", formData);

    try {
      // Use the createDriver mutation
      const result = await createDriver.mutateAsync({
        first_name: formData.first_name,
        last_name: formData.last_name,
        date_of_birth: formData.date_of_birth,
        phone_number: formData.phone_number,
        email: formData.email,
        driver_license_number: formData.driver_license_number,
        driver_license_state: formData.driver_license_state,
        driver_license_expiration_date: formData.driver_license_expiration_date,
        driver_license_class: formData.driver_license_class,
        company_id: companyId,
        employment_start_date: formData.employment_start_date,
        employment_status: formData.employment_status,
        is_verified: formData.is_verified,
      });
      console.log("Result", result);

      setMessage(`Driver added successfully.`);
      setError("");
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-1/6 flex justify-center items-start">
          <img
            src="/static/warranty.png"
            alt="Warranty"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">Add Driver</h2>
            <p className="text-slate-500 text-sm">Enter driver information.</p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              <InputField
                label="First Name"
                placeholder="John"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
              />
              <InputField
                label="Last Name"
                placeholder="Doe"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
              />
              <InputField
                label="Date of Birth"
                placeholder="YYYY-MM-DD"
                name="date_of_birth"
                value={formData.date_of_birth}
                onChange={handleInputChange}
              />
              <InputField
                label="Phone Number"
                placeholder="123-456-7890"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
              />
              <InputField
                label="Email"
                placeholder="john.doe@example.com"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <InputField
                label="Driver License Number"
                placeholder="D1234567"
                name="driver_license_number"
                value={formData.driver_license_number}
                onChange={handleInputChange}
              />
              <InputField
                label="Driver License State"
                placeholder="CA"
                name="driver_license_state"
                value={formData.driver_license_state}
                onChange={handleInputChange}
              />
              <InputField
                label="Driver License Expiration Date"
                placeholder="YYYY-MM-DD"
                name="driver_license_expiration_date"
                value={formData.driver_license_expiration_date}
                onChange={handleInputChange}
              />
              <InputField
                label="Driver License Class"
                placeholder="Class A"
                name="driver_license_class"
                value={formData.driver_license_class}
                onChange={handleInputChange}
              />
              <InputField
                label="Employment Start Date"
                placeholder="YYYY-MM-DD"
                name="employment_start_date"
                value={formData.employment_start_date}
                onChange={handleInputChange}
              />
            </div>

            {error && <p className="text-red-500 mt-6">{error}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}

            <div className="space-y-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddDriver;
