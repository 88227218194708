// @ts-nocheck
import React, { useState } from "react";
import InputField from "../../Components/InputField";
import { useCustomerCompanyId } from "../../../hooks/customers";
import { useGetUserSession } from "../../../hooks/users";
import { useCreateCarrier } from "../../../hooks/carriers";

const AddCarrier = () => {
  const { session, loading } = useGetUserSession();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    country: "USA",
    phone_number: "",
    email: "",
    website: "",
    dot_number: "",
    mc_number: "",
    tax_id: "",
    number_of_trucks: "",
    number_of_drivers: "",
    safety_rating: "",
    insurance_provider: "",
    insurance_policy_number: "",
    is_active: true,
    notes: "",
  });

  const { mutate: createCarrier } = useCreateCarrier();

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting form", formData);

    try {
      // Use the createCarrier mutation
      console.log("Creating carrier", formData);
      createCarrier(formData, {
        onSuccess: (result) => {
          console.log("Result", result);
          setMessage(`Carrier added successfully.`);
          setError("");
        },
        onError: (error) => {
          setError("An error occurred. Please try again.");
          console.error("Error:", error);
        },
      });
    } catch (error) {
      setError("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full mx-auto p-6 bg-white rounded-lg border border-slate-300 shadow-md">
      <div className="flex flex-col md:flex-row gap-6 pb-2">
        <div className="md:w-1/6 flex justify-center items-start">
          <img
            src="/static/warranty.png"
            alt="Warranty"
            className="w-16 h-16 rounded-full"
          />
        </div>
        <div className="md:w-5/6 flex flex-col gap-6">
          <div className="space-y-2">
            <h2 className="text-slate-900 text-xl font-semibold">
              Add Carrier
            </h2>
            <p className="text-slate-500 text-sm">Enter carrier information.</p>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
              <InputField
                label="Name"
                placeholder="Carrier Name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <InputField
                label="Address"
                placeholder="123 Main St"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
              <InputField
                label="City"
                placeholder="City"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
              <InputField
                label="State"
                placeholder="State"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
              <InputField
                label="Zip Code"
                placeholder="12345"
                name="zip_code"
                value={formData.zip_code}
                onChange={handleInputChange}
              />
              <InputField
                label="Country"
                placeholder="Country"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
              />
              <InputField
                label="Phone Number"
                placeholder="123-456-7890"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
              />
              <InputField
                label="Email"
                placeholder="carrier@example.com"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <InputField
                label="Website"
                placeholder="https://example.com"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
              />
              <InputField
                label="DOT Number"
                placeholder="DOT123456"
                name="dot_number"
                value={formData.dot_number}
                onChange={handleInputChange}
              />
              <InputField
                label="MC Number"
                placeholder="MC123456"
                name="mc_number"
                value={formData.mc_number}
                onChange={handleInputChange}
              />
              <InputField
                label="Tax ID"
                placeholder="123-45-6789"
                name="tax_id"
                value={formData.tax_id}
                onChange={handleInputChange}
              />
              <InputField
                label="Number of Trucks"
                placeholder="10"
                name="number_of_trucks"
                value={formData.number_of_trucks}
                onChange={handleInputChange}
              />
              <InputField
                label="Number of Drivers"
                placeholder="5"
                name="number_of_drivers"
                value={formData.number_of_drivers}
                onChange={handleInputChange}
              />
              <InputField
                label="Safety Rating"
                placeholder="A+"
                name="safety_rating"
                value={formData.safety_rating}
                onChange={handleInputChange}
              />
              <InputField
                label="Insurance Provider"
                placeholder="Provider Name"
                name="insurance_provider"
                value={formData.insurance_provider}
                onChange={handleInputChange}
              />
              <InputField
                label="Insurance Policy Number"
                placeholder="Policy123"
                name="insurance_policy_number"
                value={formData.insurance_policy_number}
                onChange={handleInputChange}
              />
              <InputField
                label="Notes"
                placeholder="Additional notes"
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
              />
            </div>

            {error && <p className="text-red-500 mt-6">{error}</p>}
            {message && <p className="text-green-500 mt-6">{message}</p>}

            <div className="space-y-4">
              <button
                type="submit"
                className="w-full px-4 py-2 bg-slate-900 text-white text-sm font-medium rounded-md hover:bg-slate-800 transition-colors"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCarrier;
