import React from "react";

const PhotoFlowComponent = ({
  title,
  subtitle,
  steps,
  currentStep,
  children,
  onSubmit,
  onBack,
  buttonText = "Next",
  isButtonDisabled = false,
  showBackButton = true,
  verification,
}) => {
  return (
    <div className="w-full min-h-screen p-2 bg-slate-900 flex flex-col justify-start items-center">
      <div className="w-full p-4 shadow flex justify-center items-center">
        <div className="w-32 h-6 flex justify-center items-center gap-2" />
      </div>
      <div className="w-full flex-grow flex justify-center items-center">
        <div className="w-full max-w-md bg-white/0 backdrop-blur-[25px] flex flex-col justify-center items-center">
          <div className="w-full p-4 bg-white rounded-lg shadow border border-slate-300 flex flex-col justify-start items-start gap-4">
            <div className="w-full flex flex-col justify-start items-start gap-6">
              <div className="w-full flex flex-col justify-start items-start">
                <div className="text-slate-900 text-2xl font-semibold font-['Sharp Sans Display No1']">
                  {title}
                </div>
                <div className="w-full text-slate-500 text-sm font-medium font-['Sharp Sans Display No1']">
                  {subtitle}
                </div>
              </div>
              <div className="w-full h-12 rounded-full border border-slate-200 flex justify-start items-start">
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`flex-1 h-12 px-3 py-1 text-center ${
                      index === 0
                        ? "rounded-l-full" // First step: rounded on the left
                        : index === steps.length - 1
                        ? "rounded-r-full" // Last step: rounded on the right
                        : "rounded-none" // Middle steps: no rounded corners
                    } ${
                      index < currentStep
                        ? "bg-slate-200"
                        : "bg-slate-50 border border-slate-200"
                    } flex flex-col justify-center items-start`}
                  >
                    <div className="w-full text-slate-800 text-xs font-medium font-['Sharp Sans Display No1']">
                      {step}
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex flex-col justify-start items-center gap-4">
                {children}
                <div className="w-full flex justify-between items-center gap-4">
                  <button
                    className={`flex-1 px-4 py-2 rounded-md text-sm font-medium font-['Sharp Sans Display No1'] leading-normal ${
                      !showBackButton || currentStep <= 1
                        ? "bg-slate-100 text-slate-400 cursor-not-allowed"
                        : "bg-red-50 text-slate-900 border border-red-200 hover:bg-red-100"
                    }`}
                    onClick={onBack}
                    disabled={!showBackButton || currentStep <= 1}
                  >
                    Back
                  </button>
                  <button
                    className={`flex-1 px-4 py-2 rounded-md text-sm font-medium font-['Sharp Sans Display No1'] leading-normal ${
                      isButtonDisabled
                        ? "bg-slate-100 text-slate-400 cursor-not-allowed"
                        : "bg-green-50 text-slate-900 border border-green-200 hover:bg-green-100"
                    }`}
                    onClick={onSubmit}
                    disabled={isButtonDisabled}
                  >
                    {currentStep === steps.length ? "Complete" : buttonText}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-md flex justify-center items-center mt-4">
        <div className="text-white text-sm font-semibold font-['Sharp Sans Display No1'] mr-4">
          Contact support
        </div>
      </div>
    </div>
  );
};

export default PhotoFlowComponent;
