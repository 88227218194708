import React from "react";
import {
  HomeIcon,
  GlobeAltIcon,
  TruckIcon,
  Square3Stack3DIcon,
  ArchiveBoxIcon,
  CheckCircleIcon,
  ShieldCheckIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { supabase } from "../../lib/supabase";

const DashboardIcon = ({ icon: Icon, text, to }) => (
  <Link
    to={to}
    className="w-full flex items-center gap-2 px-3 py-2 rounded-md hover:bg-white transition-colors duration-200"
  >
    <Icon className="w-5 h-5 text-slate-700" />
    <span className="text-sm font-medium text-slate-800">{text}</span>
  </Link>
);

const Sidebar = () => {
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div className="w-1/8 min-w-[200px] max-w-[300px] h-screen py-6 bg-slate-200 flex flex-col justify-between">
      <div className="px-2 space-y-1">
        <DashboardIcon icon={HomeIcon} text="Home" to="/dashboard" />
        <DashboardIcon
          icon={ShieldCheckIcon} // Or ShieldCheckIcon
          text="Verifications"
          to="/dashboard/verifications"
        />
        <DashboardIcon
          icon={Square3Stack3DIcon}
          text="Shipments"
          to="/dashboard/shipments"
        />
        <DashboardIcon
          icon={TruckIcon}
          text="Drivers"
          to="/dashboard/drivers"
        />
        <DashboardIcon
          icon={GlobeAltIcon}
          text="Carriers"
          to="/dashboard/carriers"
        />
        <DashboardIcon
          icon={ArchiveBoxIcon}
          text="Cargo Owners"
          to="/dashboard/cargo-owners"
        />
        <DashboardIcon
          icon={ArrowPathIcon}
          text="Workflow"
          to="/dashboard/workflows"
        />
      </div>
      <div className="px-2 flex flex-col items-center gap-2.5">
        <div className="w-10 h-10 bg-[#119da5] rounded-full flex items-center justify-center">
          <span className="text-slate-50 text-base font-normal font-['Inter']">
            OD
          </span>
        </div>
        <button
          onClick={handleLogout}
          className="w-full px-3 py-2 text-slate-900 text-sm font-medium hover:bg-slate-300 rounded-md transition-colors duration-200"
        >
          Log out
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
