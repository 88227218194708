import BaseCache from "./BaseCache";

class MemoryCache extends BaseCache {
  constructor(options) {
    super(options);
    this.cache = new Map();
  }

  get(key) {
    const entry = this.cache.get(key);
    if (!entry) return null;

    if (Date.now() - entry.timestamp > this.options.duration) {
      this.delete(key);
      return null;
    }

    return entry.value;
  }

  set(key, value) {
    if (this.cache.size >= this.options.maxSize) {
      const oldestKey = this.cache.keys().next().value;
      this.delete(oldestKey);
    }

    this.cache.set(key, {
      value,
      timestamp: Date.now(),
    });
  }

  delete(key) {
    this.cache.delete(key);
  }

  clear() {
    this.cache.clear();
  }
}

export default MemoryCache;
