import React from "react";
import { BaseNode } from "./BaseNode";
import {
  BellIcon,
  DocumentTextIcon,
  FlagIcon,
} from "@heroicons/react/20/solid";

export const EndNode = ({ data, isConnectable }) => {
  const getCompletionIcon = () => {
    switch (data.completionAction) {
      case "notify":
        return <BellIcon className="size-5 text-blue-500" />;
      case "report":
        return <DocumentTextIcon className="size-5 text-yellow-500" />;
      case "both":
        return <BellIcon className="size-5 text-green-500" />;
      default:
        return <FlagIcon className="size-5 text-gray-500" />;
    }
  };

  return (
    <BaseNode data={data} isConnectable={isConnectable} type="endNode">
      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow">
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-sm font-medium text-gray-900">End Node</h3>
              <span className="inline-flex shrink-0 items-center rounded-full bg-gray-50 px-1.5 py-0.5 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-600/20">
                Completion
              </span>
            </div>
            <div className="mt-3">
              <select
                className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={data.completionAction}
                onChange={(e) =>
                  data.onChange?.("completionAction", e.target.value)
                }
              >
                <option value="none">No Action</option>
                <option value="notify">Send Notification</option>
                <option value="report">Generate Report</option>
                <option value="both">Notify & Report</option>
              </select>
            </div>
          </div>
          <div className="shrink-0 rounded-full bg-gray-100 p-2">
            {getCompletionIcon()}
          </div>
        </div>

        {(data.completionAction === "notify" ||
          data.completionAction === "both") && (
          <div className="px-6 py-4">
            <div className="space-y-3">
              <label className="flex items-center gap-x-3 rounded-lg border border-gray-200 p-3 hover:bg-gray-50">
                <input
                  type="checkbox"
                  checked={data.notifyStakeholders?.broker}
                  onChange={(e) =>
                    data.onChange?.("notifyStakeholders", {
                      ...data.notifyStakeholders,
                      broker: e.target.checked,
                    })
                  }
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <span className="text-sm font-medium text-gray-900">
                  Notify Broker
                </span>
              </label>
              <label className="flex items-center gap-x-3 rounded-lg border border-gray-200 p-3 hover:bg-gray-50">
                <input
                  type="checkbox"
                  checked={data.notifyStakeholders?.cargoOwner}
                  onChange={(e) =>
                    data.onChange?.("notifyStakeholders", {
                      ...data.notifyStakeholders,
                      cargoOwner: e.target.checked,
                    })
                  }
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
                <span className="text-sm font-medium text-gray-900">
                  Notify Cargo Owner
                </span>
              </label>
            </div>
          </div>
        )}
      </div>
    </BaseNode>
  );
};
