// @ts-nocheck
import { supabase } from "../lib/supabase";
import { createPersonaInquiry } from "./PersonaService";
import OCRService from "./OCRService";
import { sendVerificationSMS } from "./NotificationService";
import { ShipmentService } from "./ShipmentService";

export async function createVerification(data) {
  const { referenceId, hostedFlowUrl, inquiryId } = await createPersonaInquiry(
    data
  );
  console.log("data", data);

  // Update existing shipment
  const { data: shipment, error: shipmentError } = await supabase
    .from("shipments")
    .update({
      status: "Unverified",
      company_id: data.companyId,
    })
    .eq("load_id", data.shipment_id)
    .select()
    .single();

  if (shipmentError) throw shipmentError;

  const { data: verification, error } = await supabase
    .from("verifications")
    .insert({
      phone_number: data.phoneNumber,
      shipment_id: shipment.load_id, // Use the newly created shipment's ID
      driver_id: data.driver_id,
      custom_reference: referenceId,
      persona_inquiry_id: inquiryId,
      persona_verification_url: hostedFlowUrl,
      persona_verification_complete: false,
      state: "created",
      ocr_verification_complete: false,
    })
    .select()
    .single();

  console.log("verification", verification);

  if (error) throw error;

  await sendVerificationSMS(verification);
  return { verification, shipment };
}

export async function getVerificationStatus(shipmentId) {
  const { data, error } = await supabase
    .from("verifications")
    .select("*")
    .eq("shipment_id", shipmentId)
    .single();

  if (error) throw error;
  return data;
}

export async function updatePersonaStatus(customReference, status) {
  const { error } = await supabase
    .from("verifications")
    .update({
      persona_verification_complete: status === "completed",
      state: status === "completed" ? "persona_complete" : "created",
    })
    .eq("custom_reference", customReference);

  if (error) throw error;
}

/**
 * Calculates the Levenshtein distance between two strings.
 * This measures the difference between two sequences by counting
 * the minimum number of single-character edits required to change one string into the other.
 */
function calculateLevenshteinDistance(a, b) {
  const matrix = Array.from({ length: b.length + 1 }, (_, i) => [i]);

  for (let i = 0; i <= a.length; i++) {
    matrix[0][i] = i;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b[i - 1] === a[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1 // deletion
        );
      }
    }
  }

  return matrix[b.length][a.length];
}

/**
 * Normalizes OCR text by removing unwanted characters and extra spaces.
 *
 * @param {string} ocrText - The OCR text to be cleaned.
 * @returns {string} The normalized OCR text.
 */
function normalizeOCRText(ocrText) {
  return ocrText
    .replace(/[^a-zA-Z0-9\s:-]/g, "") // Remove non-alphanumeric and non-essential characters
    .replace(/\s+/g, " ") // Normalize spaces
    .trim();
}

/**
 * Performs OCR verification on a shipment document.
 *
 * @param {string} shipmentId - The ID of the shipment to be verified.
 * @param {File} imageFile - The file object of the captured image (not used in this function).
 * @param {string} imageURL - The URL of the uploaded image.
 * @returns {Promise<Object>} An object containing OCR results, verification results, and shipment details.
 */
async function updateVerificationStatus(shipmentId, updates) {
  const { data, error } = await supabase
    .from("verifications")
    .update(updates)
    .eq("shipment_id", shipmentId)
    .single();

  if (error) throw error;
  return data;
}

export async function performVerificationOCR(shipmentId, imageFile, imageURL) {
  // Use the helper function to get verification
  const verification = await getVerificationStatus(shipmentId);

  // Process image using OCR service
  const ocrResult = await OCRService.processImage(imageURL, shipmentId);

  // Fetch shipment details using the shipment service
  const shipment = await ShipmentService.getShipment(verification.shipment_id);

  // Verify shipment ID from OCR results
  const verificationResult = verifyShipmentId(ocrResult.ocrText, shipmentId);

  // Update the verification status
  await updateVerificationStatus(shipmentId, {
    ocr_verification_complete: true,
    state: verificationResult.match ? "complete" : "ocr_complete",
  });

  return { ocrResult, verificationResult, shipmentDetails: shipment };
}

/**
 * Extracts all potential UUID-like substrings from the OCR text using regex.
 * This will look for substrings that match the expected pattern of a UUID,
 * allowing for minor OCR mistakes by replacing 'O' with '0'.
 *
 * @param {string} ocrText - The text extracted by the OCR process.
 * @returns {Array<string>} An array of candidate shipment IDs.
 */
function extractUuidCandidates(ocrText) {
  // Normalize OCR text first
  const normalizedText = normalizeOCRText(ocrText);

  // Relaxed regex pattern to accommodate minor OCR mistakes like 'O' instead of '0'
  const uuidRegex =
    /\b[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9a-z]{12}\b/gi;

  const candidates = normalizedText.match(uuidRegex) || [];

  console.log("Extracted UUID candidates:", candidates);
  return candidates;
}

/**
 * Finds the best match for the expected shipment ID from a list of OCR-extracted candidates.
 *
 * @param {string} expectedShipmentId - The expected shipment ID.
 * @param {Array<string>} candidates - The list of candidate strings extracted from the OCR text.
 * @returns {Object} An object containing the best-matched candidate and whether it meets the threshold.
 */
function findBestMatch(expectedShipmentId, candidates) {
  let bestMatch = null;
  let lowestDistance = Infinity;

  candidates.forEach((candidate) => {
    const distance = calculateLevenshteinDistance(
      candidate,
      expectedShipmentId
    );

    if (distance < lowestDistance) {
      lowestDistance = distance;
      bestMatch = candidate;
    }
  });

  // Define a threshold for what you consider "close enough"
  const isMatch = lowestDistance <= 4; // Adjust this threshold if necessary

  return { bestMatch, isMatch, distance: lowestDistance };
}

/**
 * Verifies if the expected shipment ID is present in the OCR-extracted text,
 * allowing for minor OCR mistakes using Levenshtein distance.
 *
 * @param {string} ocrText - The text extracted from the image by OCR.
 * @param {string} expectedShipmentId - The shipment ID that should be present in the document.
 * @returns {Object} An object containing the expected ID, the best match, and whether it matches.
 */
/**
 * Verifies if the expected shipment ID is present in the OCR-extracted text,
 * allowing for minor OCR mistakes using Levenshtein distance and normalization.
 *
 * @param {string} ocrText - The text extracted from the image by OCR.
 * @param {string} expectedShipmentId - The shipment ID that should be present in the document.
 * @returns {Object} An object containing the expected ID, the best match, and whether it matches.
 */
export function verifyShipmentId(ocrText, expectedShipmentId) {
  console.log("Verifying shipment ID");

  // Normalize and extract UUID candidates from the OCR text
  const candidates = extractUuidCandidates(ocrText);
  console.log("Extracted candidates:", candidates);

  if (candidates.length === 0) {
    return { expected: expectedShipmentId, match: false, bestMatch: null };
  }

  // Find the best match among the candidates
  const { bestMatch, isMatch, distance } = findBestMatch(
    expectedShipmentId,
    candidates
  );

  console.log("The expectedShipmentId is", expectedShipmentId);
  console.log("The bestMatch is", bestMatch);
  console.log("The Levenshtein distance is", distance);
  console.log("The isMatch is", isMatch);

  return { expected: expectedShipmentId, match: isMatch, bestMatch };
}

export const checkVerificationStatus = async (shipmentId) => {
  try {
    console.log("Checking verification status");
    const { data, error } = await supabase.functions.invoke(
      "check-persona-status",
      {
        body: { shipmentId },
      }
    );
    console.log("Verification status checked", data);
    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error checking verification status:", error);
    throw error;
  }
};
