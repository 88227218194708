import React from "react";
import { Handle, Position } from "reactflow";

export const BaseNode = ({ data, isConnectable, children, type }) => {
  const getNodeStyle = () => {
    const baseStyle = "px-4 py-2 rounded-lg shadow-md border";
    switch (type) {
      case "verification":
        return `${baseStyle} bg-blue-50 border-blue-200`;
      case "photoDoc":
        return `${baseStyle} bg-green-50 border-green-200`;
      case "statusUpdate":
        return `${baseStyle} bg-purple-50 border-purple-200`;
      case "location":
        return `${baseStyle} bg-yellow-50 border-yellow-200`;
      case "conditional":
        return `${baseStyle} bg-red-50 border-red-200`;
      case "notification":
        return `${baseStyle} bg-gray-50 border-gray-200`;
      default:
        return `${baseStyle} bg-white border-gray-200`;
    }
  };

  return (
    <div className={getNodeStyle()}>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className="min-w-[200px]">
        <div className="font-medium text-gray-700">{data.label}</div>
        {children}
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </div>
  );
};
