import React, { useEffect } from "react";
import PhotoCapture from "../Components/PhotoCapture";

const PhotoProofContent = ({
  shipmentId,
  frontPhoto,
  setFrontPhoto,
  frontPreviewUrl,
  setFrontPreviewUrl,
  backPhoto,
  setBackPhoto,
  backPreviewUrl,
  setBackPreviewUrl,
  leftPhoto,
  setLeftPhoto,
  leftPreviewUrl,
  setLeftPreviewUrl,
  rightPhoto,
  setRightPhoto,
  rightPreviewUrl,
  setRightPreviewUrl,
  handleSubmit,
}) => {
  useEffect(() => {
    console.log("Shipment ID in PhotoProofContent:", shipmentId);
  }, [shipmentId]);

  const internalHandleSubmit = async () => {
    console.log("Clicked submit");
    await handleSubmit();
  };

  return (
    <div className="h-auto flex-col justify-start items-center gap-4 inline-flex">
      <div className="w-full h-auto flex-col justify-start items-start flex">
        <div className="w-full text-slate-800 text-base font-semibold">
          Shipment Condition Verification
        </div>
        <div className="w-full text-slate-500 text-sm">
          Prevent chargebacks and damage claims by taking a photo of the
          shipment upon arrival and departure from the warehouse.
        </div>
      </div>

      <PhotoCapture
        label="Capture Front"
        photo={frontPhoto}
        setPhoto={setFrontPhoto}
        previewUrl={frontPreviewUrl}
        setPreviewUrl={setFrontPreviewUrl}
      />
      <PhotoCapture
        label="Capture Back"
        photo={backPhoto}
        setPhoto={setBackPhoto}
        previewUrl={backPreviewUrl}
        setPreviewUrl={setBackPreviewUrl}
      />
      <PhotoCapture
        label="Capture Left"
        photo={leftPhoto}
        setPhoto={setLeftPhoto}
        previewUrl={leftPreviewUrl}
        setPreviewUrl={setLeftPreviewUrl}
      />
      <PhotoCapture
        label="Capture Right"
        photo={rightPhoto}
        setPhoto={setRightPhoto}
        previewUrl={rightPreviewUrl}
        setPreviewUrl={setRightPreviewUrl}
      />

      <button onClick={internalHandleSubmit}>Submit Photos</button>
    </div>
  );
};

export default PhotoProofContent;
