import React from "react";
import { Check, Package } from "lucide-react";

const ShipmentConfirmation = ({
  isConfirmed,
  isConfirming,
  confirmShipment,
}) => {
  return (
    !isConfirmed && (
      <div className="mt-8 w-full">
        <div className="group relative overflow-hidden rounded-xl bg-white shadow-lg hover:shadow-xl transition-all duration-300">
          {/* Background decorative element */}
          <div className="absolute inset-0 bg-gradient-to-r from-blue-50 to-indigo-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

          {/* Main content container */}
          <div className="relative p-6 flex items-center gap-4">
            {/* Icon container */}
            <div className="flex-shrink-0">
              <div className="p-3 rounded-full bg-blue-50 text-blue-600">
                <Package className="h-6 w-6" />
              </div>
            </div>

            {/* Content area */}
            <div className="flex-grow">
              <div className="flex items-center gap-4">
                <div className="relative">
                  <input
                    type="checkbox"
                    id="confirmShipment"
                    checked={isConfirmed}
                    onChange={confirmShipment}
                    disabled={isConfirming}
                    className="peer h-6 w-6 rounded-md border-2 border-gray-300 
                             text-blue-600 focus:ring-blue-500 focus:ring-offset-2
                             disabled:opacity-50 disabled:cursor-not-allowed cursor-pointer
                             transition-colors duration-200"
                  />
                  <Check
                    className="absolute h-4 w-4 text-white top-1 left-1 
                             pointer-events-none opacity-0 peer-checked:opacity-100 
                             transition-opacity duration-200"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="confirmShipment"
                    className="text-lg font-medium text-gray-900 select-none cursor-pointer"
                  >
                    Confirm Shipment Receipt
                  </label>
                  <p className="text-sm text-gray-500 mt-0.5">
                    Click to confirm you've reviewed the report and acknowledge
                    the contents
                  </p>
                </div>
              </div>

              {/* Loading state */}
              {isConfirming && (
                <div className="mt-2 flex items-center gap-2 text-sm text-blue-600">
                  <div className="h-2 w-2 rounded-full bg-blue-600 animate-pulse" />
                  <span>Processing confirmation...</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ShipmentConfirmation;
