// @ts-nocheck
import { supabase } from "../../src/lib/supabase";
import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext({
  session: null,
  customer: null,
  externalUser: null,
  loading: true,
  isAdmin: false,
});

const isTestEnvironment = process.env.NODE_ENV === "test";

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [externalUser, setExternalUser] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchSession = async () => {
      try {
        const {
          data: { session: currentSession },
          error: sessionError,
        } = await supabase.auth.getSession();

        console.log("Current Session:", currentSession);

        if (sessionError) throw sessionError;
        if (!mounted) return;

        setSession(currentSession);

        if (currentSession) {
          // Fetch external user data first since we know this is an external user
          const { data: externalData, error: externalError } = await supabase
            .from("external_users")
            .select("*")
            .eq("user_id", currentSession.user.id)
            .single();

          console.log("External User Data:", externalData);
          console.log("External User Error:", externalError);

          if (!mounted) return;

          if (externalData) {
            setExternalUser(externalData);
          } else {
            // Only try customer if external user not found
            const { data: customerData } = await supabase
              .from("customers")
              .select("*")
              .eq("user_id", currentSession.user.id)
              .single();

            if (!mounted) return;
            if (customerData) {
              setCustomer(customerData);
            }
          }
        }
      } catch (error) {
        console.error("Auth Provider Error:", error);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchSession();
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        session,
        customer,
        externalUser,
        loading,
        isAdmin: customer?.role === "ADMIN",
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
