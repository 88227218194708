// @ts-nocheck
import React, { forwardRef } from "react";

const Textarea = forwardRef(
  (
    {
      className = "",
      error,
      label,
      placeholder = "",
      disabled = false,
      rows = 4,
      onChange,
      value,
      ...props
    },
    ref
  ) => {
    return (
      <div className="w-full">
        {label && (
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {label}
          </label>
        )}
        <textarea
          ref={ref}
          rows={rows}
          disabled={disabled}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`
          w-full
          px-3
          py-2
          text-sm
          rounded-md
          border
          bg-white
          placeholder:text-gray-400
          disabled:cursor-not-allowed
          disabled:opacity-50
          ${
            error
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-300 focus:ring-blue-500"
          }
          focus:outline-none
          focus:ring-2
          focus:ring-offset-0
          focus:border-transparent
          transition-colors
          ${className}
        `}
          {...props}
        />
        {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
      </div>
    );
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
