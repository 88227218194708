import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { toast } from "react-hot-toast";

const CargoOwnerUsers = () => {
  const { id: cargoOwnerId } = useParams();
  const [users, setUsers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, [cargoOwnerId]);

  const fetchUsers = async () => {
    try {
      const { data: response, error } = await supabase.functions.invoke(
        "get-external-users",
        {
          body: { cargoOwnerId },
        }
      );

      if (error) throw error;
      setUsers(response.data);
    } catch (error) {
      toast.error("Failed to fetch users");
      console.error("Error fetching users:", error);
    }
  };

  const handleDeactivateUser = async (userId) => {
    try {
      setIsLoading(true);
      const { error } = await supabase.functions.invoke("deactivate-user", {
        body: { userId },
      });

      if (error) throw error;

      toast.success("User deactivated successfully");
      fetchUsers();
    } catch (error) {
      toast.error("Failed to deactivate user");
      console.error("Error deactivating user:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const generateTemporaryPassword = () => {
    const length = 8;
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_+-=[]{}|;:,.<>?";

    const allChars = uppercase + lowercase + numbers + symbols;
    let password = "";

    // Ensure at least one of each type
    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += symbols[Math.floor(Math.random() * symbols.length)];

    // Fill remaining length with random characters
    for (let i = password.length; i < length; i++) {
      password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Shuffle the password
    return password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
  };

  const createExternalUser = async (email) => {
    if (!email) {
      toast.error("Please enter an email address");
      return;
    }

    const tempPassword = generateTemporaryPassword();
    try {
      setIsLoading(true);
      console.log("Invoking create-external-user with:", {
        email,
        cargoOwnerId,
        tempPassword,
      });

      const { data, error } = await supabase.functions.invoke(
        "create-external-user",
        {
          body: {
            email,
            tempPassword,
            cargoOwnerId,
          },
        }
      );

      console.log("Response:", { data, error });

      if (error) {
        if (
          error.message?.includes("email already exists") ||
          error.code === "email_exists"
        ) {
          toast.error("A user with this email already exists");
        } else if (error.code === "user_exists") {
          toast.error("A user already exists for this cargo owner");
        } else {
          throw error;
        }
        return;
      }

      toast.success("User created successfully");
      setNewUserEmail("");
      fetchUsers();
    } catch (error) {
      console.error("Full error object:", error);
      toast.error(
        `Failed to create user: ${
          error.message || error.error || "Unknown error"
        }`
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Manage External Users</h2>

      {/* Add User Form */}
      <div className="mb-8">
        <input
          type="email"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          placeholder="Enter email address"
          className="mr-2 p-2 border w-1/4 rounded focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-slate-900"
          aria-label="New user email"
        />
        <button
          onClick={() => createExternalUser(newUserEmail)}
          disabled={isLoading}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50"
        >
          {isLoading ? "Adding..." : "Add User"}
        </button>
      </div>

      {/* User Table */}
      <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-sm">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                User Details
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Added Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users.map((user) => (
              <tr key={user.user_id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {user.email}
                  </div>
                  <div className="text-sm text-gray-500">
                    ID: {user.user_id}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-900">
                    {new Date(user.created_at).toLocaleDateString()}
                  </div>
                  <div className="text-sm text-gray-500">
                    {new Date(user.created_at).toLocaleTimeString()}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Active
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    onClick={() => handleDeactivateUser(user.user_id)}
                    className="text-red-600 hover:text-red-900 font-medium focus:outline-none focus:underline"
                    aria-label={`Deactivate user ${user.email}`}
                  >
                    Deactivate
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CargoOwnerUsers;
