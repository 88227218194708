// @ts-nocheck
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { Button } from "../Components/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../Components/Card";
import { Input } from "../Components/Input";
import { Label } from "../Components/Label";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreeTerms) {
      alert("Please agree to the terms of use");
      return;
    }
    if (!email || !password) {
      alert("Please enter both email and password");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Invalid email format");
      return;
    }

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      navigate("/dashboard");
    } catch (error) {
      console.error("Error signing in:", error.message);
      alert("Sign in failed. Please check your credentials and try again.");
    }
  };

  return (
    <div className="relative h-screen w-full bg-custom-photo bg-no-repeat bg-cover flex justify-center items-center">
      {/* Sign-in card */}
      <Card className="w-full max-w-md shadow-lg rounded-lg overflow-hidden z-10">
        <form onSubmit={handleSubmit}>
          <CardHeader className="text-left">
            <CardTitle className="text-2xl font-bold">
              Sign in to your account
            </CardTitle>
            <CardDescription className="text-sm text-gray-600 mt-2">
              Sign in to unlock our full risk management tool
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-4 px-6">
            <div className="space-y-1">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="Email"
                className="w-full bg-white"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="space-y-1">
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                placeholder="Password"
                className="w-full bg-white"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-center space-x-2 mt-2">
              <input
                type="checkbox"
                id="terms"
                className="form-checkbox"
                checked={agreeTerms}
                onChange={(e) => setAgreeTerms(e.target.checked)}
              />
              <Label htmlFor="terms" className="text-sm text-gray-600">
                I agree to our Terms of Service and Privacy Policy.
              </Label>
            </div>
          </CardContent>
          <CardFooter className="px-6 py-4">
            <Button
              type="submit"
              className="w-full bg-primary text-white py-2 rounded-md"
            >
              Sign in
            </Button>
          </CardFooter>
        </form>
      </Card>
    </div>
  );
}
