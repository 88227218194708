// @ts-nocheck
import { supabase } from "../lib/supabase";

export async function createPersonaInquiry(userData) {
  try {
    const { data: responseData, error } = await supabase.functions.invoke(
      "create-persona-inquiry",
      {
        body: JSON.stringify({
          phoneNumber: userData.phoneNumber,
        }),
      }
    );

    console.log("responseData", responseData);

    if (error) throw error;
    if (!responseData || !responseData.success) {
      throw new Error(
        responseData?.error || "Failed to create Persona inquiry"
      );
    }

    return {
      referenceId: responseData.referenceId,
      hostedFlowUrl: responseData.hostedFlowUrl,
      inquiryId: responseData.inquiryId,
    };
  } catch (error) {
    console.error("Error creating Persona inquiry:", error);
    throw error;
  }
}

export async function updatePersonaStatus(customReference, status) {
  const { error } = await supabase
    .from("verifications")
    .update({
      persona_verification_complete: status === "completed",
      state: status === "completed" ? "persona_complete" : "created",
    })
    .eq("custom_reference", customReference);

  if (error) throw error;
}

const PersonaService = { createPersonaInquiry, updatePersonaStatus };
export default PersonaService;
