// @ts-nocheck
import React, { useState, useMemo } from "react";
import { useShipmentByCargoOwnerId, useShipments } from "../../hooks/shipments";
import { Card, CardContent } from "../Components/Card";
import { Input } from "../Components/Input";
import { Button } from "../Components/Button";
import { Select } from "../Components/Select";
import { Loader2 } from "lucide-react";
import {
  FiSearch,
  FiFilter,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import Toast from "../Components/Toast";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";

const ITEMS_PER_PAGE = 10;

const ShipmentList = () => {
  const { id: cargoOwnerId } = useParams();
  console.log("cargoOwnerId", cargoOwnerId);

  const navigate = useNavigate();

  // State for filters and sorting
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    search: "",
    cargoOwnerId: cargoOwnerId,
    orderBy: "created_at",
    orderDirection: "desc",
  });

  // Should be changed to:
  const { data, isLoading, error } = useShipmentByCargoOwnerId(
    currentPage,
    ITEMS_PER_PAGE,
    filters,
    cargoOwnerId
  );

  const { shipments = [], total = 0 } = data || {};

  // Memoized filter options
  const statusOptions = useMemo(
    () => [
      { value: "", label: "All Statuses" },
      { value: "pending", label: "Pending" },
      { value: "in_transit", label: "In Transit" },
      { value: "verified", label: "Verified" },
      { value: "unverified", label: "Unverified" },
    ],
    []
  );

  const sortOptions = useMemo(
    () => [
      { value: "created_at", label: "Date Created" },
      { value: "reference_number", label: "Reference Number" },
      { value: "status", label: "Status" },
    ],
    []
  );

  // Handle filter changes
  const handleFilterChange = (value) => {
    setFilters((prev) => ({
      ...prev,
      search: value,
    }));
    setCurrentPage(1);
  };

  // Handle pagination
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil((total || 0) / ITEMS_PER_PAGE);

  // Update this function to handle report viewing/generation
  const handleViewDetails = async (shipment) => {
    try {
      // Check for existing token
      const { data: tokenData, error: tokenError } = await supabase
        .from("shipments")
        .select("public_token")
        .eq("id", shipment.id)
        .single();

      if (tokenError) throw tokenError;

      let token;

      // Only generate a new token if one doesn't exist
      if (!tokenData?.public_token) {
        token = crypto.randomUUID();

        // Update shipment with new token
        const { error: updateError } = await supabase
          .from("shipments")
          .update({ public_token: token })
          .eq("id", shipment.id);

        if (updateError) throw updateError;
      } else {
        token = tokenData.public_token;
      }

      // Open in a new tab
      window.open(`/public-report/${token}`, "_blank");
    } catch (error) {
      console.error("Error handling report view:", error);
      Toast.error("Failed to generate report link");
    }
  };

  if (error) {
    return <Toast type="error" message={error.message} />;
  }

  return (
    <div className="space-y-6">
      {/* Filters */}
      <Card>
        <CardContent className="space-y-4">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold">Search</h2>
            <Button
              variant="outline"
              className="bg-white text-gray-900 font-semibold mt-2 w-1/4 border-2 border-gray-200"
              onClick={() =>
                setFilters({
                  search: "",
                  cargoOwnerId: cargoOwnerId,
                  orderBy: "created_at",
                  orderDirection: "desc",
                })
              }
            >
              Clear Search
            </Button>
          </div>
          <div className="relative">
            <Input
              placeholder="Search load ID..."
              value={filters.search}
              onChange={(e) => handleFilterChange(e.target.value)}
              icon={<FiSearch className="w-4 h-4" />}
              className="bg-white text-gray-900 font-semibold border-2 border-gray-200"
            />
          </div>
        </CardContent>
      </Card>

      {/* Shipments Table */}
      <Card>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="w-8 h-8 animate-spin" />
            </div>
          ) : (
            <>
              <div className="overflow-x-auto min-h-[500px]">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Shipment ID
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Created At
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Updated At
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Condition
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {shipments?.length > 0 ? (
                      shipments.map((shipment) => (
                        <tr
                          key={shipment.id}
                          className={`
                          ${shipment.status !== "verified" ? "opacity-50" : ""}
                        `}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            {shipment.load_id}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                              ${
                                shipment.status === "Verified"
                                  ? "bg-green-100 text-green-800"
                                  : ""
                              }
                              ${
                                shipment.status === "In Transit"
                                  ? "bg-yellow-100 text-yellow-800"
                                  : ""
                              }
                              ${
                                shipment.status === "Pending"
                                  ? "bg-gray-100 text-gray-800"
                                  : ""
                              }
                              ${
                                shipment.status === "Unverified"
                                  ? "bg-red-100 text-red-800"
                                  : ""
                              }
                            `}
                            >
                              {shipment.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {new Date(shipment.created_at).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {new Date(shipment.updated_at).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {shipment.condition || "N/A"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <Button
                              variant="link"
                              onClick={() => handleViewDetails(shipment)}
                              disabled={shipment.status !== "Verified"}
                              className={`
                                ${
                                  shipment.status !== "Verified"
                                    ? "cursor-not-allowed text-gray-900 font-semibold"
                                    : "text-green-800 hover:text-green-900 font-semibold"
                                }
                              `}
                            >
                              {shipment.status === "Verified"
                                ? "View Report"
                                : "Pending Verification"}
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="5"
                          className="px-6 py-4 text-center text-gray-500"
                        >
                          No shipments found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="flex items-center justify-between px-4 py-3 border-t">
                <div className="flex-1 flex justify-between sm:hidden">
                  <Button
                    className="bg-white text-gray-900 font-semibold border-2 border-gray-200"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Button
                    className="bg-white text-gray-900 font-semibold border-2 border-gray-200"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </Button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing page {currentPage} of {totalPages}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <Button
                      className="bg-white text-gray-900 font-semibold border-2 border-gray-200"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <FiChevronLeft className="w-4 h-4" />
                    </Button>
                    {/* Page numbers */}
                    {Array.from({ length: Math.min(5, totalPages) }, (_, i) => (
                      <Button
                        key={i + 1}
                        className="bg-white text-gray-900 font-semibold border-2 border-gray-200"
                        variant={currentPage === i + 1 ? "default" : "outline"}
                        onClick={() => handlePageChange(i + 1)}
                      >
                        {i + 1}
                      </Button>
                    ))}
                    <Button
                      className="bg-white text-gray-900 font-semibold border-2 border-gray-200"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <FiChevronRight className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ShipmentList;
