// @ts-nocheck
import { useState, useEffect } from "react";
import { Input } from "./Input";
import Textarea from "./TextArea";
import { Button } from "./Button";
import { FiEdit2, FiSave, FiX, FiPlus } from "react-icons/fi";

const EditableField = ({ label, value, onSave, type = "text" }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value || []);
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    setTempValue(value || []);
  }, [value]);

  const handleSave = () => {
    onSave(tempValue);
    setIsEditing(false);
  };

  const handleAddTag = () => {
    if (!newTag.trim()) return;
    const updatedTags = [...tempValue, { name: newTag.trim() }];
    setTempValue(updatedTags);
    setNewTag("");
  };

  const handleRemoveTag = (indexToRemove) => {
    setTempValue(tempValue.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="space-y-1">
      <label className="text-sm font-medium text-slate-500">{label}</label>
      <div className="flex flex-col gap-2">
        {isEditing ? (
          <>
            {type === "tags" ? (
              <div className="space-y-2">
                <div className="flex flex-wrap gap-2">
                  {tempValue.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-slate-100 rounded-full text-xs text-slate-600 flex items-center gap-1"
                    >
                      {tag.name}
                      <button
                        onClick={() => handleRemoveTag(index)}
                        className="hover:text-red-500"
                      >
                        <FiX className="w-3 h-3" />
                      </button>
                    </span>
                  ))}
                </div>
                <div className="flex gap-2">
                  <Input
                    value={newTag}
                    onChange={(e) => setNewTag(e.target.value)}
                    placeholder="Add new tag"
                    className="flex-1 bg-white"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddTag();
                      }
                    }}
                  />
                  <Button size="sm" variant="ghost" onClick={handleAddTag}>
                    <FiPlus className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            ) : type === "textarea" ? (
              <Textarea
                value={tempValue}
                onChange={(e) => setTempValue(e.target.value)}
                className="flex-1 bg-white min-h-[60px]"
              />
            ) : (
              <Input
                type={type}
                value={tempValue}
                onChange={(e) => setTempValue(e.target.value)}
                className="flex-1 bg-white"
              />
            )}
            <div className="flex gap-1 mt-2">
              <Button size="sm" variant="ghost" onClick={handleSave}>
                <FiSave className="w-4 h-4" />
              </Button>
              <Button
                size="sm"
                variant="ghost"
                onClick={() => setIsEditing(false)}
              >
                <FiX className="w-4 h-4" />
              </Button>
            </div>
          </>
        ) : (
          <>
            {type === "tags" ? (
              <div className="flex flex-wrap gap-2">
                {value.length > 0 ? (
                  value.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-slate-100 rounded-full text-xs text-slate-600"
                    >
                      {tag.name}
                    </span>
                  ))
                ) : (
                  <p className="text-sm text-slate-900">No tags available</p>
                )}
              </div>
            ) : (
              <p className="flex-1 text-sm text-slate-900">
                {value || "Not specified"}
              </p>
            )}
            <Button
              size="sm"
              variant="ghost"
              onClick={() => setIsEditing(true)}
            >
              <FiEdit2 className="w-4 h-4" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default EditableField;
