// @ts-nocheck
import { z } from "zod";

// Validation schema
const customerSchema = z.object({
  first_name: z.string().max(255).nullable(),
  last_name: z.string().max(255).nullable(),
  phone_number: z.string().max(255).nullable(),
  email: z.string().email("Invalid email address").max(255).nullable(),
  role: z.string().default("Manager").nullable(),
  user_id: z.string().uuid().nullable(),
  company_access_id: z.string().nullable(),
  company_id: z.number().int().positive().nullable(),
});

export default customerSchema;
