// @ts-nocheck
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ShipmentService } from "../api/ShipmentService";

export const useShipments = (page = 1, limit = 1000, filters = {}) => {
  return useQuery({
    queryKey: ["shipments", page, limit, filters],
    queryFn: () => ShipmentService.getShipments(page, limit, filters),
    keepPreviousData: true,
    staleTime: 30000,
    cacheTime: 300000,
  });
};

export const useShipmentByCargoOwnerId = (
  page = 1,
  limit = 10,
  filters = {},
  cargoOwnerId
) => {
  return useQuery({
    queryKey: ["shipments", page, limit, filters, cargoOwnerId],
    queryFn: () =>
      ShipmentService.getShipments(page, limit, filters, cargoOwnerId),
  });
};

export const useShipment = (id, cargoOwnerId = null) => {
  return useQuery({
    queryKey: ["shipment", id, cargoOwnerId],
    queryFn: () => ShipmentService.getShipment(id, cargoOwnerId),
    enabled: !!id,
  });
};

export const useShipmentMutations = (cargoOwnerId = null) => {
  const queryClient = useQueryClient();

  const invalidateShipments = async (id) => {
    if (cargoOwnerId) {
      await queryClient.invalidateQueries({
        queryKey: ["shipments", { cargoOwnerId }],
      });
      if (id) {
        await queryClient.invalidateQueries({
          queryKey: ["shipment", id, cargoOwnerId],
        });
      }
    } else {
      await queryClient.invalidateQueries({ queryKey: ["shipments"] });
      if (id) {
        await queryClient.invalidateQueries({ queryKey: ["shipment", id] });
      }
    }
  };

  const updateShipmentStatus = useMutation({
    mutationFn: ({ id, status }) =>
      ShipmentService.updateShipmentStatus(id, status),
    onMutate: async ({ id, status }) => {
      await queryClient.cancelQueries({
        queryKey: ["shipment", id, cargoOwnerId],
      });

      const previousShipment = queryClient.getQueryData([
        "shipment",
        id,
        cargoOwnerId,
      ]);

      queryClient.setQueryData(["shipment", id, cargoOwnerId], (old) => ({
        ...old,
        status,
        verified_at: new Date().toISOString(),
      }));

      return { previousShipment };
    },
    onError: (err, variables, context) => {
      if (context?.previousShipment) {
        queryClient.setQueryData(
          ["shipment", variables.id, cargoOwnerId],
          context.previousShipment
        );
      }
    },
    onSettled: (_, __, { id }) => {
      invalidateShipments(id);
    },
  });

  const createShipment = useMutation({
    mutationFn: ShipmentService.createShipment,
    onSuccess: () => invalidateShipments(),
  });

  const updateShipment = useMutation({
    mutationFn: ({ id, shipmentData }) =>
      ShipmentService.updateShipment(id, shipmentData),
    onSuccess: (_, { id }) => invalidateShipments(id),
  });

  const deleteShipment = useMutation({
    mutationFn: ShipmentService.deleteShipment,
    onSuccess: (_, id) => invalidateShipments(id),
  });

  const addShipmentPhoto = useMutation({
    mutationFn: ({ id, photoData }) =>
      ShipmentService.addShipmentPhoto(id, photoData),
    onSuccess: (_, { id }) => invalidateShipments(id),
  });

  return {
    createShipment,
    updateShipment,
    deleteShipment,
    updateShipmentStatus,
    addShipmentPhoto,
  };
};
