import React from "react";
import AddShipment from "./AddShipment";

const AddShipmentForm = () => {
  return (
    <div className="min-h-screen bg-white flex flex-row gap-10 p-10">
      <AddShipment />
    </div>
  );
};

export default AddShipmentForm;
