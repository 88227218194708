import React from "react";
import {
  Package,
  Clock,
  Truck,
  CheckCircle,
  AlertTriangle,
  XCircle,
} from "lucide-react";

const StatCard = ({ title, value, color, icon: Icon }) => (
  <div className="flex-1 min-w-[150px] p-4 bg-slate-50 rounded-lg border border-slate-200 flex flex-col justify-between">
    <div className="flex justify-between items-start">
      <div>
        <p className="text-slate-800 text-xs sm:text-sm font-semibold opacity-70">
          {title}
        </p>
        <p className="text-slate-800 text-base sm:text-lg font-semibold mt-2">
          {value}
        </p>
      </div>
      <div
        className={`w-10 h-10 ml-2 bg-opacity-20 rounded-full flex items-center justify-center ${color}`}
      >
        <Icon className={`w-6 h-6 ${color.replace("bg-", "text-")}`} />
      </div>
    </div>
  </div>
);

const SummaryStats = ({ data }) => {
  const initialCounts = {
    completed: 0,
    inProgress: 0,
    total: 0,
    reportTypes: {},
  };

  const counts = data.reduce((acc, report) => {
    acc.total += 1;
    if (report.status === "Completed") {
      acc.completed += 1;
    } else if (report.status === "In Progress") {
      acc.inProgress += 1;
    }

    if (report.report_type) {
      acc.reportTypes[report.report_type] =
        (acc.reportTypes[report.report_type] || 0) + 1;
    }

    return acc;
  }, initialCounts);

  const { completed, inProgress, total, reportTypes } = counts;

  return (
    <div className="rounded-xl space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
        <StatCard
          title="Total Reports"
          value={total}
          color="bg-blue-400"
          icon={Package}
        />
        <StatCard
          title="Completed"
          value={completed}
          color="bg-green-400"
          icon={CheckCircle}
        />
        <StatCard
          title="In Progress"
          value={inProgress}
          color="bg-yellow-400"
          icon={Clock}
        />
        {Object.entries(reportTypes).map(([type, count]) => (
          <StatCard
            key={type}
            title={type}
            value={count}
            color="bg-purple-400"
            icon={Truck}
          />
        ))}
      </div>
    </div>
  );
};

export default SummaryStats;
