// @ts-nocheck
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";
import { supabase } from "../../../lib/supabase";
import { Button } from "../../Components/Button";
import { Input } from "../../Components/Input";
import Textarea from "../../Components/TextArea";
import Toast from "../../Components/Toast";
import MapComponent from "../../Components/MapComponent";
import { usePhotos } from "../../../hooks/photos";
import EditableField from "../../Components/EditableField";

const ReportDetails = () => {
  console.log("ReportDetails component rendered");
  const { id } = useParams();
  const navigate = useNavigate();
  const [shipment, setShipment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [reportUrl, setReportUrl] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [emailDetails, setEmailDetails] = useState({
    to: "",
    subject: "",
    message: "",
  });

  const {
    photos,
    setPhotos,
    isLoading: photosLoading,
    error: photosError,
    isLoadingMore,
  } = usePhotos(id);

  // Fetch shipment details
  useEffect(() => {
    const fetchShipment = async () => {
      try {
        const { data, error } = await supabase
          .from("shipments")
          .select("*")
          .eq("id", id)
          .single();

        if (error) throw error;
        setShipment(data);
      } catch (err) {
        console.error("Error fetching shipment:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchShipment();
  }, [id]);

  const groupedPhotos = useMemo(() => {
    if (photos?.length > 0) {
      return photos.reduce((acc, photo) => {
        const stage = photo.stage_name || "Uncategorized";
        if (!acc[stage]) acc[stage] = [];
        acc[stage].push(photo);
        return acc;
      }, {});
    }
    return {};
  }, [photos]);

  const handleEmailChange = useCallback((e) => {
    const { name, value } = e.target;
    setEmailDetails((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handlePhotoClick = useCallback((photo) => {
    setSelectedPhoto(photo);
  }, []);

  const sendEmail = useCallback(async () => {
    try {
      const response = await fetch("/api/resend", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...emailDetails,
          pdfUrl: reportUrl,
        }),
      });

      if (!response.ok) throw new Error("Failed to send email");

      Toast.success("Email sent successfully");
    } catch (error) {
      console.error("Error sending email:", error);
      Toast.error("Failed to send email");
    }
  }, [emailDetails, reportUrl]);

  const handleUpdatePhotoTags = async (photoId, newTags) => {
    try {
      // Update in Supabase
      console.log("Updating tags for photo", photoId, newTags);
      const { error } = await supabase
        .from("photos")
        .update({ tags: newTags })
        .eq("id", photoId);

      if (error) throw error;

      // Update the local state
      setPhotos((currentPhotos) =>
        currentPhotos.map((photo) =>
          photo.id === photoId ? { ...photo, tags: newTags } : photo
        )
      );

      Toast.success("Tags updated successfully");
    } catch (error) {
      console.error("Error updating tags:", error);
      Toast.error("Failed to update tags");
    }
  };

  if (isLoading || photosLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="w-8 h-8 animate-spin" />
      </div>
    );
  }

  if (photosError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-red-600">
          Error loading photos: {photosError.message}
        </p>
      </div>
    );
  }

  if (!shipment) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-slate-600">Shipment not found</p>
        <Button
          variant="link"
          onClick={() => navigate("/shipments")}
          className="mt-4"
        >
          Back to Shipments
        </Button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-white h-full">
      <div className="space-y-8">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-slate-900">
            Shipment Details
          </h1>
        </div>

        {/* Email Form */}
        <div className="space-y-4">
          <Input
            name="to"
            value={emailDetails.to}
            onChange={handleEmailChange}
            placeholder="Recipient Email"
          />
          <Input
            name="subject"
            value={emailDetails.subject}
            onChange={handleEmailChange}
            placeholder="Subject"
          />
          <Textarea
            name="message"
            value={emailDetails.message}
            onChange={handleEmailChange}
            placeholder="Message"
          />
          <Button onClick={sendEmail}>Send Email</Button>
        </div>

        {/* Report Preview */}
        {reportUrl ? (
          <iframe
            src={reportUrl}
            className="w-1/5 h-96 border rounded-lg"
            title="Report Preview"
          ></iframe>
        ) : (
          <div className="flex flex-col">
            <h1 className="text-2xl font-bold text-slate-900">
              No Report Available - Generate Report Below
            </h1>
            <Button
              onClick={() => navigate(`/generate-report/${id}`)}
              className="mt-4 w-auto"
            >
              Generate Report
            </Button>
          </div>
        )}
      </div>

      {/* Photos Section */}
      <div className="space-y-8">
        <h2 className="text-xl font-semibold text-slate-900">
          Photos by Stage
        </h2>

        {/* Map showing all photo locations */}
        {photos.length > 0 && (
          <div className="h-96 w-full rounded-lg overflow-hidden">
            <MapComponent
              markers={photos
                .filter(
                  (photo) => photo.origin_latitude && photo.origin_longitude
                )
                .map((photo) => ({
                  lat: parseFloat(photo.origin_latitude),
                  lng: parseFloat(photo.origin_longitude),
                  label: photo.stage_name,
                }))}
            />
          </div>
        )}

        {/* Loading more indicator */}
        {isLoadingMore && (
          <div className="text-center py-4">
            <Loader2 className="w-6 h-6 animate-spin mx-auto" />
            <p className="text-sm text-slate-600 mt-2">
              Loading more photos...
            </p>
          </div>
        )}

        {/* Photos grouped by stage */}
        {Object.entries(groupedPhotos).map(([stageName, stagePhotos]) => (
          <div key={stageName} className="space-y-4">
            <h3 className="text-lg font-medium text-slate-800">{stageName}</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {stagePhotos.map((photo) => (
                <div key={photo.id} className="border rounded-lg p-4 space-y-3">
                  {photo.url ? (
                    <img
                      src={photo.url}
                      alt={photo.caption || "Shipment photo"}
                      className="w-full h-48 object-cover rounded-lg cursor-pointer"
                      loading="lazy"
                      onClick={() => handlePhotoClick(photo)}
                    />
                  ) : (
                    <div className="w-full h-48 bg-gray-200 animate-pulse rounded-lg" />
                  )}

                  <div className="space-y-2">
                    {photo.origin_latitude && photo.origin_longitude && (
                      <p className="text-sm text-slate-600">
                        Location: {photo.origin_latitude},{" "}
                        {photo.origin_longitude}
                      </p>
                    )}

                    {photo && (
                      <div className="flex flex-wrap gap-2">
                        <EditableField
                          label="Tags"
                          type="tags"
                          value={photo.tags || []}
                          onSave={(newTags) =>
                            handleUpdatePhotoTags(photo.id, newTags)
                          }
                        />
                      </div>
                    )}

                    {photo.metadata && (
                      <div className="text-xs text-slate-500">
                        <p>
                          Taken:{" "}
                          {new Date(
                            photo.metadata.timing.takenAt
                          ).toLocaleString()}
                        </p>
                        <p>Device: {photo.metadata.deviceInfo.model}</p>
                        <p>Quality Score: {photo.metadata.quality.blurScore}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Photo Modal */}
      {selectedPhoto && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="max-w-4xl w-full bg-white rounded-lg p-4">
            <img
              src={selectedPhoto.url}
              alt={selectedPhoto.caption || "Full size photo"}
              className="w-full h-auto"
            />
            <button
              onClick={() => setSelectedPhoto(null)}
              className="mt-4 px-4 py-2 bg-slate-800 text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportDetails;
